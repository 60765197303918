import { v4 as uuidv4 } from 'uuid';

export function generateUuid() {
  return uuidv4().toUpperCase().replace(/-/g, '');
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
