import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import Checkbook from '../Checkbook/Checkbook';
import Profile from '../Profile/Profile';
import ListAddresses from '../Addresses/ListAddresses';
import ListBankAccounts from '../BankAccounts/ListBankAccounts';

const { Title, Text } = Typography;

function Account() {
  return (
    <>
      <CSPage>
        <CSPageHeader title="Account" />
        <Profile />
        <Checkbook />
        <ListBankAccounts />
        <ListAddresses />
      </CSPage>
      <style jsx>{`
        .status-text {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 24px;
          }
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 20px;
          }
        }
        .headline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          height: 48px;
        }
        .detail {
          width: 50%;
          margin-bottom: 8px;
        }
        @media screen and (max-width: 600px) {
          .detail {
            width: 100%;
          }
        }
        .detail-content {
          font-size: 14px;
        }
      `}</style>
      <style jsx global>{`
        .details-card .ant-card-body {
          padding: 0px;
        }
        .detail-label {
          text-transform: uppercase;
          font-size: 11px;
          color: #858585;
          margin-bottom: 8px;
        }
        .status-card {
          margin-bottom: 55px;
        }
        @media screen and (max-width: 992px) {
          .status-card {
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default Account;
