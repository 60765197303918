import { useQuery, gql } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import autocomplete from '../../../shared/autocomplete';
import apolloClient from '../../../apollo-client';
import { BehaviorSubject, from, of } from 'rxjs';

const addressAutoCompleteQuery = gql`
  query AddressAutoComplete($address: AddressAutoCompleteInput!) {
    addressAutoComplete(address: $address) {
      address
      city
      state
      zipCode
    }
  }
`;

function useAddressAutoComplete() {
  const term$ = useRef(null);
  const results$ = useRef(null);
  const [addressOptions, setAddressOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getAutocompleteSuggestions = useCallback((_term) => {
    const doAsyncStuff = async (term) => {
      if (!term) return undefined;
      setLoading(true);
      setError(null);
      let results;
      try {
        const result = await apolloClient.query({
          query: addressAutoCompleteQuery,
          variables: {
            address: {
              address: term,
            },
          },
          fetchPolicy: 'network-only',
        });
        if (result && result.data && result.data.addressAutoComplete) {
          results = result.data.addressAutoComplete;
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);

      return (
        results &&
        results.map((result) => ({
          value: `${result.address} ${result.city} ${result.state} ${result.zipCode}`,
          address: result,
        }))
      );
    };
    return from(doAsyncStuff(_term));
  }, []);

  useEffect(() => {
    term$.current = new BehaviorSubject('');
    results$.current = term$.current.pipe(
      autocomplete(500, (term) => getAutocompleteSuggestions(term)),
    );
    results$.current.subscribe((results) => setAddressOptions(results));
  }, [getAutocompleteSuggestions]);

  const addressSearch = useCallback((term) => {
    if (term) {
      term$.current.next(term);
    }
  }, []);

  return {
    loading,
    error,
    addressSearch,
    addressOptions,
  };
}

export default useAddressAutoComplete;
