import React from 'react';
import { Layout, Space, Typography } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { firebaseApp } from './shared/firebase-functions';
import SignIn from './pages/SignIn';
import { AuthContextProvider } from './shared/auth-context';
import AppLoading from './components/AppLoading';
import SignUp from './pages/SignUp';
import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';
import Dashboard from './pages/Dashboard';
import Checks from './pages/Checks';
import Payments from './pages/Payments';
import MainNavMenu from './components/MainNavMenu';
import Version from './components/Version';
import Profile from './pages/Profile';
import EditProfile from './pages/Profile/EditProfile';
import ListBanksAccounts from './pages/bank-accounts/ListBankAccounts';
import EditBankAccount from './pages/bank-accounts/EditBankAccount';
import Billing from './pages/Billing';
import useResponsiveMode from './shared/use-reponsive-mode';
import { useWindowDimensions } from './shared/window-dimensions';
import { shallowEqual, useSelector } from 'react-redux';
import ListAddresses from './pages/addresses/ListAddresses';
import settings from './settings';
import EditAddress from './pages/addresses/EditAddress';
import ListChecks from './pages/checks/ListChecks';
import EditCheck from './pages/checks/EditCheck';
import CheckDetails from './pages/checks/CheckDetails';
import ConfirmCheckAndPay from './pages/checks/ConfirmCheckAndPay';
import Account from './pages/Account/Account/Account';
import BuyChecks from './pages/Account/Checkbook/BuyChecks';
import BuyChecksSuccess from './pages/Account/Checkbook/BuyChecksSuccess';

const { Text } = Typography;

const App = () => {
  useWindowDimensions();
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <AuthContextProvider>
      {(context) => (
        <>
          {context.state.isLoading ? (
            <AppLoading />
          ) : (
            <ApolloProvider client={client}>
              <Router>
                {context.state.user ? (
                  <Layout
                    hasSider={responsiveMode !== 'mobile'}
                    style={{
                      minHeight: '100vh',
                      background: settings.colors.ghost,
                    }}
                  >
                    <MainNavMenu />
                    <Layout
                      style={{
                        background: settings.colors.ghost,
                        boxShadow:
                          responsiveMode !== 'mobile'
                            ? 'inset 1px 0px 10px rgba(0, 0, 0, 0.05)'
                            : undefined,
                      }}
                    >
                      <Switch>
                        <Route
                          exact
                          path="/"
                          render={() => <Redirect to="/home" />}
                        />
                        <Route
                          exact
                          path="/sign-(in|up)"
                          render={() => <Redirect to="/home" />}
                        />
                        <Route exact path="/home" component={Dashboard} />
                        <Route
                          exact
                          path="/addresses"
                          component={ListAddresses}
                        />
                        <Route
                          exact
                          path="/address/edit/:_id"
                          component={EditAddress}
                        />
                        <Route exact path="/billing" component={Billing} />
                        <Route exact path="/payments" component={Payments} />
                        <Route exact path="/account" component={Account} />
                        <Route exact path="/checks/buy" component={BuyChecks} />
                        <Route
                          exact
                          path="/checks/buy/success"
                          component={BuyChecksSuccess}
                        />
                        <Route
                          exact
                          path="/profile/edit"
                          component={EditProfile}
                        />
                        <Route
                          exact
                          path="/bank-accounts"
                          component={ListBanksAccounts}
                        />
                        <Route
                          exact
                          path="/bank-account/edit/:_id"
                          component={EditBankAccount}
                        />
                        <Route exact path="/checks" component={ListChecks} />
                        <Route
                          exact
                          path="/check/edit/:_id"
                          component={EditCheck}
                        />
                        <Route
                          exact
                          path="/check/confirm/:_id"
                          component={ConfirmCheckAndPay}
                        />
                        <Route
                          exact
                          path="/check/details/:_id"
                          component={CheckDetails}
                        />
                      </Switch>
                      {/* <Layout.Footer
                        style={{
                          backgroundColor: 'white',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>Check Supply ©{new Date().getFullYear()}</Text>
                        <Version />
                      </Layout.Footer> */}
                    </Layout>
                  </Layout>
                ) : (
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/sign-up" />}
                    />
                    <Route exact path="/sign-up" component={SignUp} />
                    <Route exact path="/sign-in" component={SignIn} />
                  </Switch>
                )}
              </Router>
            </ApolloProvider>
          )}
        </>
      )}
    </AuthContextProvider>
  );
};

export default App;
