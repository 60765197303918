import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteCheckAction } from '../../redux-store/checks-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

const deleteCheckMutation = gql`
  mutation deleteCheck($id: ID!) {
    deleteCheck(id: $id)
  }
`;

function DeleteCheckModal({ check, setCheck }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteCheck] = useMutation(deleteCheckMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await deleteCheck({
        variables: {
          id: check.id,
        },
      });
      dispatch(deleteCheckAction(check.id));
      message.success('Check deleted successfully');
      history.goBack();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [check, deleteCheck, dispatch, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setCheck(null);
    }
  }, [setCheck, loading]);

  return (
    <YesNoModal
      title="Delete Check"
      question={`Are you sure you want to delete your check "${
        check &&
        (check.description ||
          check.memo ||
          (check.toAddress && check.toAddress.name))
      }"?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!check}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteCheckModal;
