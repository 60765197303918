import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Details from '../../../components/Details';
import settings from '../../../settings';
import ProfilePic from './ProfilePic';
import useProfile from './use-profile';

const { Title, Text } = Typography;

function Profile() {
  const profile = useProfile();
  const history = useHistory();

  const locale = useSelector((store) => store.locale, shallowEqual);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const details = useMemo(() => {
    const _details = [
      {
        id: 'name',
        label: 'Name',
        content: null,
      },
      {
        id: 'email',
        label: 'Email',
        content: null,
      },
      {
        id: 'phoneNumber',
        label: 'Phone Number',
        content: null,
      },
      {
        id: 'birthday',
        label: 'Birthday',
        content: null,
      },
    ];
    if (profile) {
      _details[0].content = profile.displayName;
      _details[1].content = profile.email;
      _details[2].content = profile.phoneNumber;
      _details[3].content = new Intl.DateTimeFormat(locale, {
        dateStyle: 'long',
      }).format(new Date(profile.birthday));
    }
    return _details;
  }, [profile, locale]);

  return (
    <>
      <div className="headline">
        <Title level={3} style={{ fontWeight: 500, margin: 0 }}>
          Profile
        </Title>
      </div>
      <Card
        style={{
          marginBottom: 55,
          boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.05)',
          borderRadius: 5,
          overflow: 'hidden',
          border: 0,
        }}
        className="details-card"
      >
        <Row style={{ flexWrap: 'wrap' }}>
          <Col
            md={8}
            sm={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <ProfilePic profile={profile} />
          </Col>
          <Col
            md={16}
            sm={24}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div className="edit-btn-container">
              <Tooltip title="Edit Profile">
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => history.push('/profile/edit')}
                />
              </Tooltip>
            </div>
            <Details details={details} style={{ paddingTop: 8 }} />
          </Col>
        </Row>
      </Card>
      <style jsx>{`
        .status-text {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 24px;
          }
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 20px;
          }
        }
        .headline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          height: 48px;
        }
        .detail {
          width: 50%;
          margin-bottom: 8px;
        }
        @media screen and (max-width: 600px) {
          .detail {
            width: 100%;
          }
        }
        .detail-content {
          font-size: 14px;
        }
        .edit-btn-container {
          text-align: right;
          padding-top: 16px;
          padding-right: 16px;
          padding-right: 16px;
        }
      `}</style>
      <style jsx global>{`
        .details-card .ant-card-body {
          padding: 0px;
        }
        .detail-label {
          text-transform: uppercase;
          font-size: 11px;
          color: #858585;
          margin-bottom: 8px;
        }
        .status-card {
          margin-bottom: 55px;
        }
        @media screen and (max-width: 992px) {
          .status-card {
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default Profile;
