import { Skeleton, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function Details({ details, style = {} }) {
  return (
    <div className="details" style={{ ...style }}>
      {details.map((detail) => (
        <div className="detail" key={detail.id}>
          <div>
            <Text className="detail-label">{detail.label}</Text>
          </div>
          <div className="detail-content">
            <Skeleton
              active
              loading={!detail.content}
              title={false}
              paragraph
            />
            {detail.content}
          </div>
        </div>
      ))}
      <style jsx>{`
        .details {
          display: flex;
          flex-wrap: wrap;
          padding: 24px 32px;
          margin-left: -8px;
          margin-right: -8px;
          height: 100%;
        }
        .detail {
          width: 50%;
          margin-bottom: 8px;
          padding-left: 8px;
          padding-right: 8px;
        }
        @media screen and (max-width: 600px) {
          .detail {
            width: 100%;
          }
        }
        .detail-content {
          font-size: 14px;
        }
      `}</style>
    </div>
  );
}

export default Details;
