import { gql } from '@apollo/client';

export const createCheckMutation = gql`
  mutation createCheck($check: CheckCreateInput!) {
    createCheck(check: $check) {
      id
      owner
      description
      toAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      fromAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      bankAccount {
        id
        label
        bankName
        accountType
        signatory
      }
      amount
      memo
      checkNumber
      expectedDeliveryDate
      mailType
      sendDate
      thumbnails {
        medium
      }
      trackingEvents {
        lobId
        name
        location
        time
      }
      currentStatus
      cancelled
      isDraft
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const updateCheckMutation = gql`
  mutation updateCheck($check: CheckUpdateInput!) {
    updateCheck(check: $check) {
      id
      owner
      description
      toAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      fromAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      bankAccount {
        id
        label
        bankName
        accountType
        signatory
      }
      amount
      memo
      checkNumber
      expectedDeliveryDate
      mailType
      sendDate
      thumbnails {
        medium
      }
      trackingEvents {
        lobId
        name
        location
        time
      }
      currentStatus
      cancelled
      isDraft
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
