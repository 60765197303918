import React, { useCallback, useState } from 'react';
import { Typography, Button, Card, Space, Spin, Skeleton } from 'antd';
import CSPageHeader from '../../../components/CSPageHeader';
import CSPage from '../../../components/CSPage';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAddresses } from './use-addresses';
import { useHistory } from 'react-router-dom';
import { generateUuid } from '../../../shared/utils';
import { shallowEqual, useSelector } from 'react-redux';
import DisplayAddress from '../../../components/DisplayAddress';
import AddressVerificationStatus from './AddressVerificationStatus';
import settings from '../../../settings';

const { Title, Text } = Typography;

function AddressCard({ address, handleUpdate }) {
  return (
    <Button className="address-card" onClick={handleUpdate(address.id)}>
      <div className="top-line">
        <Title level={3} style={{ margin: 0 }}>
          {address.name}
        </Title>
        <AddressVerificationStatus status={address.verificationStatus} />
      </div>
      <Text type="secondary">{address.label}</Text>
      <div style={{ flex: 1 }} />
      <DisplayAddress address={address} />
      <style jsx>{`
        .top-line {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      `}</style>
      <style jsx global>{`
        .address-card {
          background: white;
          width: 350px;
          min-height: 190px;
          height: unset;
          border-radius: 5px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
          padding: 24px 28px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 24px;
        }
        @media screen and (max-width: 800px) {
          .address-card {
            width: calc(100vw - 110px);
          }
        }
        @media screen and (max-width: 600px) {
          .address-card {
            width: calc(100vw - 32px);
          }
        }
      `}</style>
    </Button>
  );
}

function NewAddressCard({ handleCreate }) {
  return (
    <Button className="new-address-card" onClick={handleCreate}>
      <Space direction="vertical" className="inner">
        <PlusCircleOutlined style={{ fontSize: 24 }} />
        Add a new address
      </Space>
      <style jsx>{`
        .inner {
        }
      `}</style>
      <style jsx global>{`
        .new-address-card {
          background: white;
          width: 350px;
          min-height: 190px;
          height: unset;
          border-radius: 5px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
          margin-bottom: 24px;
        }
        @media screen and (max-width: 800px) {
          .new-address-card {
            width: calc(100vw - 110px);
          }
        }
        @media screen and (max-width: 600px) {
          .new-address-card {
            width: calc(100vw - 32px);
          }
        }
      `}</style>
    </Button>
  );
}

function LoadingAddressCard() {
  return (
    <Button className="new-address-card">
      <Skeleton title paragraph loading active />
      <style jsx>{`
        .inner {
        }
      `}</style>
      <style jsx global>{`
        .new-address-card {
          background: white;
          width: 350px;
          min-height: 190px;
          height: unset;
          border-radius: 5px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
          margin-bottom: 24px;
        }
        @media screen and (max-width: 800px) {
          .new-address-card {
            width: calc(100vw - 110px);
          }
        }
        @media screen and (max-width: 600px) {
          .new-address-card {
            width: calc(100vw - 32px);
          }
        }
      `}</style>
    </Button>
  );
}

function ListAddresses() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);

  const handleCreate = useCallback(() => {
    const addressId = generateUuid();
    history.push(`address/edit/${addressId}`, { mode: 'create' });
  }, [history]);

  const handleUpdate = useCallback(
    (addressId) => () => {
      history.push(`address/edit/${addressId}`, { mode: 'update' });
    },
    [history],
  );

  const { error, loading, addresses, refetch } = useAddresses(
    settings.pageSize,
    {},
  );

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <>
      <div className="headline">
        <Title level={3} style={{ fontWeight: 500, margin: 0 }}>
          Addresses
        </Title>
      </div>
      {loading && (
        <Space
          className="body"
          size="large"
          direction="horizontal"
          style={{ flexWrap: 'wrap' }}
        >
          {[0, 1, 2].map((index) => (
            <LoadingAddressCard key={index} />
          ))}
        </Space>
      )}
      {!loading && (
        <Space size="large" direction="horizontal" style={{ flexWrap: 'wrap' }}>
          {addresses.map((address) => (
            <AddressCard
              key={address.id}
              address={address}
              handleUpdate={handleUpdate}
            />
          ))}
          <NewAddressCard handleCreate={handleCreate} />
        </Space>
      )}
      {error && <Text type="danger">{error}</Text>}
      <style jsx>{`
        .headline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          height: 48px;
        }
      `}</style>
    </>
  );
}

export default ListAddresses;
