export const Statuses = [
  {
    label: 'Received',
    value: 'received',
  },
  {
    label: 'Printing',
    value: 'printing',
  },
  {
    label: 'Mailed',
    value: 'mailed',
  },
  {
    label: 'In Transit',
    value: 'in-transit',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  },
];

export function getCheckStatus(check) {
  switch (check.currentStatus) {
    case 'CREATED':
      if (new Date() - new Date(check.createdAt) > 300000) {
        return 'printing';
      } else {
        return 'received';
      }
    case 'MAILED':
      return 'mailed';
    case 'IN_TRANSIT':
    case 'IN_LOCAL_AREA':
      return 'in-transit';
    case 'PROCESSED_FOR_DELIVERY':
      if (new Date() - new Date(check.updatedByLob) > 43200000) {
        return 'delivered';
      } else {
        return 'in-transit';
      }
    case 'REROUTED':
      return 'in-transit';
    case 'RETURNED_TO_SENDER':
      return 'returned';
    case 'CANCELLED':
      return 'cancelled';
  }
}
