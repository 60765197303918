const settings = {
  pageSize: 1000,
  apiUrl: process.env.REACT_APP_API_URL,
  stripeApiSecretKey: process.env.REACT_APP_STRIPE_API_SECRET_KEY,
  stripeApiPublicKey: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY,
  screenSizes: {
    lg: 992,
    md: 600,
    sm: 400,
  },
  colors: {
    primary: '#3BDB95',
    warning: '#E8BD4E',
    ghost: '#f0f0f0',
    gray: '#E5E5E5',
    error: '#ff4d4f',
    red: '#f5222d',
  },
};

export default settings;
