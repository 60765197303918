import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, List, Space, Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FancyListPage from '../../../components/FancyListPage';
import { generateUuid } from '../../../shared/utils';
import DeleteBankAccountModal from '../DeleteBankAccountModal';
import VerificationStatuses from '../get-verification-status';
import { useBanksAccounts } from './use-banks-accounts';
import { capitalize } from '../../../shared/utils';
import VerifyBankAccountModal from '../VerifyBankAccountModal';
import settings from '../../../settings';

const { Title, Text } = Typography;

function ListItem({
  record,
  locale,
  setDeleting,
  style,
  responsiveMode,
  isLast,
  history,
  setVerifying,
}) {
  const vStatus = VerificationStatuses[record.verificationStatus];
  const buttonSize = responsiveMode === 'mobile' ? 'small' : 'middle';
  return (
    <List.Item key={record.id} style={{ ...style }} className="list-item">
      <List.Item.Meta
        title={record.label}
        description={
          <div>
            <div>
              <Text>{record.bankName}</Text>
            </div>
            <div>
              <Text>{capitalize(record.accountType)}</Text>
            </div>
            {`Connected ${Intl.DateTimeFormat(locale).format(
              new Date(record.createdAt),
            )}`}
            <div style={{ height: 8 }} />
            {responsiveMode === 'mobile' && (
              <Space>
                <div>
                  <Space align="center">
                    {vStatus.icon}
                    {vStatus.text}
                  </Space>
                </div>
              </Space>
            )}
          </div>
        }
      />
      <Space size="middle">
        {responsiveMode !== 'mobile' && (
          <div>
            <Space align="center">
              {vStatus.icon}
              {vStatus.text}
            </Space>
          </div>
        )}
        {record.verificationStatus === 'not-verified' && (
          <Button
            type="primary"
            onClick={() => setVerifying(record)}
            size={buttonSize}
          >
            Verify
          </Button>
        )}
        <Space>
          <Tooltip title="Edit">
            <Button
              onClick={() => history.push(`/bank-account/edit/${record.id}`)}
              icon={<EditOutlined />}
              size={buttonSize}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              onClick={() => setDeleting(record)}
              icon={<DeleteOutlined />}
              size={buttonSize}
            />
          </Tooltip>
        </Space>
      </Space>
      {!isLast && <div className="bottom-border" />}
      <style jsx>{`
        .bottom-border {
          position: absolute;
          bottom: 0;
          border-bottom: 1px solid #bdbdbd;
          left: 55px;
          right: 55px;
        }
        @media screen and (max-width: 992px) {
          .bottom-border {
            left: 55px;
            right: 55px;
          }
        }
        @media screen and (max-width: 600px) {
          .bottom-border {
            left: 16px;
            right: 16px;
          }
        }
      `}</style>
    </List.Item>
  );
}

function ListBanksAccounts() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);
  const [verifying, setVerifying] = useState(null);

  const { error, loading, bankAccounts, refetch } = useBanksAccounts(
    settings.pageSize,
    {},
  );

  const handleCreate = useCallback(() => {
    const bankAccountId = generateUuid();
    history.push(`bank-account/edit/${bankAccountId}`);
  }, [history]);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  const getListItemHeight = useCallback(
    () => (responsiveMode === 'mobile' ? 150 : 120),
    [responsiveMode],
  );

  return (
    <FancyListPage
      data={bankAccounts}
      getListItemHeight={getListItemHeight}
      topActionsHeight={60}
      loading={loading}
      error={error}
      title="Bank Accounts"
      renderListItem={({ index, style, isLast }) => (
        <ListItem
          record={bankAccounts[index]}
          style={style}
          setDeleting={setDeleting}
          locale={locale}
          responsiveMode={responsiveMode}
          isLast={isLast}
          history={history}
          setVerifying={setVerifying}
        />
      )}
      topActions={[
        <Button
          key="create"
          type="link"
          onClick={handleCreate}
          icon={<PlusOutlined />}
        >
          Connect Bank Account
        </Button>,
      ]}
    >
      <DeleteBankAccountModal
        setBankAccount={setDeleting}
        bankAccount={deleting}
      />
      <VerifyBankAccountModal
        setBankAccount={setVerifying}
        bankAccount={verifying}
      />
    </FancyListPage>
  );
}

export default ListBanksAccounts;
