import { useQuery, gql } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { updateAddressAction } from '../../../redux-store/addresses-store';

const addressQuery = gql`
  query Address($id: ID!) {
    address(id: $id) {
      id
      owner
      label
      name
      company
      address1
      address2
      city
      state
      zipCode
      country
      verificationStatus
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export default function useAddress(_id) {
  const { loading, error, data } = useQuery(addressQuery, {
    variables: {
      id: _id,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (data && data.address) {
      dispatch(updateAddressAction(data.address));
    }
  }, [data, dispatch]);

  const address = useSelector((store) => store.addresses[_id], shallowEqual);

  return {
    address,
    loading,
    error,
  };
}
