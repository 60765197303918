import { useQuery, gql } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { updateCheckAction } from '../../../redux-store/checks-store';

const checkQuery = gql`
  query Check($id: ID!) {
    check(id: $id) {
      id
      owner
      description
      toAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      fromAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      bankAccount {
        id
        label
        bankName
        accountType
        signatory
      }
      amount
      memo
      checkNumber
      expectedDeliveryDate
      mailType
      sendDate
      thumbnails {
        medium
      }
      trackingEvents {
        lobId
        name
        location
        time
      }
      currentStatus
      cancelled
      isDraft
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export default function useCheck(_id) {
  const { loading, error, data } = useQuery(checkQuery, {
    variables: {
      id: _id,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (data && data.check) {
      dispatch(updateCheckAction(data.check));
    }
  }, [data, dispatch]);

  const check = useSelector((store) => store.checks[_id], shallowEqual);

  return {
    check,
    loading,
    error,
  };
}
