import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  AutoComplete,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import apolloClient from '../../../apollo-client';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useProfile from '../../Profile/use-profile';
import DeleteAddressModal from '../DeleteAddressModal';
import VerifyAddressModal from '../VerifyAddressModal';
import States from './states.json';
import useAddress from './use-address';
import useAddressAutoComplete from './use-address-autocomplete';

const verifyAddressMutation = gql`
  mutation VerifyAddressHouse($address: VerifyAddressInput!) {
    verifyAddress(address: $address) {
      name
      address1
      address2
      city
      state
      zipCode
      verifiedAddressId
      deliverability
      score
      level
    }
  }
`;

function EditAddress() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [verifiedResults, setVerifiedResults] = useState(null);

  const { _id } = useParams();

  const addressResult = useAddress(_id);
  const { address } = addressResult;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const profile = useProfile();
  const labelInput = useRef(null);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (address && !loading && !onlyOnce.current) {
      form.setFieldsValue(address);
      onlyOnce.current = true;
    }
  }, [form, address, loading]);

  const handleBack = useCallback(() => history.goBack(), [history]);

  const onSubmit = useCallback(
    async (values) => {
      console.log('onSubmit', values);
      setError(null);
      setLoading(true);
      try {
        const result = await apolloClient.mutate({
          mutation: verifyAddressMutation,
          variables: {
            address: {
              name: values.name || (address && address.name),
              company: values.company || (address && address.company),
              address1: values.address1 || (address && address.address1),
              address2: values.address2 || (address && address.address2),
              city: values.city || (address && address.city),
              state: values.state || (address && address.state),
              zipCode: values.zipCode || (address && address.zipCode),
            },
          },
          fetchPolicy: 'no-cache',
        });
        if (result && result.data && result.data.verifyAddress) {
          setVerifiedResults({
            values,
            verifiedResult: result.data.verifyAddress,
          });
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [address],
  );

  const {
    addressSearch,
    addressOptions,
    loading: autoCompleteLoading,
    error: autoCompleteError,
  } = useAddressAutoComplete();

  const onAddressSelect = useCallback(
    (value, option) => {
      form.setFieldsValue({
        address1: option.address.address,
        city: option.address.city,
        state: option.address.state,
        zipCode: option.address.zipCode,
      });
    },
    [form],
  );

  const onAddressSearch = useCallback(
    (value) => {
      addressSearch(value);
    },
    [addressSearch],
  );

  const { title, showDelete } = useMemo(() => {
    let creating;
    if (loading) {
      if (location.state.mode && location.state.mode === 'create') {
        creating = true;
      } else {
        creating = false;
      }
    } else if (!loading) {
      if (address) {
        creating = false;
      } else {
        creating = true;
      }
    }
    let _title;
    let _showDelete;
    if (creating) {
      _title = 'Create an Address';
      _showDelete = false;
    } else {
      _title = 'Edit Address';
      _showDelete = true;
    }
    return { title: _title, showDelete: _showDelete };
  }, [loading, address, location.state.mode]);

  return (
    <>
      <CSPage>
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to addresses
            </Button>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="editAddress"
          form={form}
          style={{ maxWidth: 500 }}
        >
          <Form.Item
            label="Label"
            name="label"
            rules={[]}
            extra="e.g., My house, office ..."
          >
            <Input ref={labelInput} disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a name' }]}
            extra="Sender or recipient's full name"
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item label="Company" name="company" rules={[]}>
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Address Line 1"
            name="address1"
            rules={[{ required: true, message: 'Please enter an address' }]}
            extra="Start typing and select an address"
          >
            <AutoComplete
              options={addressOptions}
              onSelect={onAddressSelect}
              onSearch={onAddressSearch}
              placeholder="1234 Captain Jack Ln. New York, NY"
              notFoundContent={autoCompleteLoading ? <Spin /> : 'No matches'}
              autocomplete="chrome-off"
              disabled={loading}
            />
          </Form.Item>
          <Form.Item label="Address Line 2" name="address2" rules={[]}>
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: 'Please enter a city' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: 'Please enter a state' }]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginLeft: 16,
              }}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                autocomplete="chrome-off"
                disabled={loading}
              >
                {States.map((state) => (
                  <Select.Option
                    key={state.abbreviation}
                    value={state.abbreviation}
                  >
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Zip Code"
            name="zipCode"
            rules={[
              { required: true, message: 'Please enter a zip code' },
              {
                pattern: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
                message: 'Please enter a valid zip code.',
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input disabled={loading} />
          </Form.Item>
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                key="cancel"
                onClick={handleBack}
                htmlType="button"
                type="text"
                size="small"
                disabled={loading}
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: -7 }}
              >
                Cancel
              </Button>
              <Button
                key="send"
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Save Address
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{error}</div>
            </div>
          )}
          {showDelete && (
            <>
              <Divider />
              <div className="delete-box">
                <Space align="center">
                  <Button
                    onClick={() => setDeleting(address)}
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                  >
                    Delete Address
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Form>
        <div style={{ minHeight: 300 }} />
        <DeleteAddressModal setAddress={setDeleting} address={deleting} />
        <VerifyAddressModal
          setVerifiedResults={setVerifiedResults}
          verifiedResults={verifiedResults}
          ownerId={profile.uid}
          addressId={_id}
          shouldUpdate={!!address}
        />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditAddress;
