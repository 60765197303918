import React from 'react';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography;

const VerificationStatuses = {
  'not-verified': {
    icon: (
      <ExclamationCircleOutlined style={{ color: '#f5222d', fontSize: 18 }} />
    ),
    text: <Text style={{ color: '#f5222d' }}>Not Verified</Text>,
  },
  verified: {
    icon: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    text: <Text style={{ color: '#52c41a' }}>Verified</Text>,
  },
};

export default VerificationStatuses;
