import { Form, InputNumber, message, Space, Spin, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { updateBankAccountAction } from '../../redux-store/bank-accounts-store';
import { useDispatch } from 'react-redux';

const verifyBankAccountMutation = gql`
  mutation VerifyBankAccount($bankAccountId: ID!, $amounts: [Int]) {
    verifyBankAccount(bankAccountId: $bankAccountId, amounts: $amounts) {
      id
      owner
      label
      bankName
      accountType
      signatory
      verificationStatus
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

const { Title, Text } = Typography;

const VerifyBankAccountModal = ({ bankAccount, setBankAccount }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [verifyBankAccount] = useMutation(verifyBankAccountMutation);
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    if (!loading) {
      setBankAccount(null);
    }
  }, [loading, setBankAccount]);

  // const handleVerify = useCallback(() => {
  //   form.submit();
  // }, [form]);

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      setError(null);
      try {
        const result = await verifyBankAccount({
          variables: {
            bankAccountId: bankAccount.id,
            amounts: [values.amount1, values.amount2],
          },
        });
        dispatch(updateBankAccountAction(result.data.verifyBankAccount));
        message.success('Bank account successfully verified');
        setBankAccount(null);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [setBankAccount, bankAccount, verifyBankAccount, dispatch],
  );

  const labelInput = useRef(null);
  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, [bankAccount]);
  return (
    <>
      <Modal
        title="Verify bank account"
        visible={!!bankAccount}
        closable={!loading}
        destroyOnClose
        maskClosable={!loading}
        cancelButtonProps={{ disabled: loading }}
        cancelText="Cancel"
        onCancel={handleCancel}
        okButtonProps={{
          form: 'verifyBankAccount',
          htmlType: 'submit',
        }}
        okText="Verify"
        onOk={undefined}
        confirmLoading={loading}
      >
        {bankAccount && (
          <Form
            layout="vertical"
            onFinish={onSubmit}
            id="verifyBankAccount"
            form={form}
            style={{ maxWidth: 500 }}
          >
            <Form.Item>
              <div>
                <Text strong>{bankAccount.label}</Text>
              </div>
              <div>
                <Text strong>{bankAccount.bankName}</Text>
              </div>
              <div style={{ marginTop: 16 }}>
                <Text>
                  Enter the two amounts (in cents) that were deposited into your
                  bank account
                </Text>
              </div>
            </Form.Item>
            <Form.Item
              label="Amount 1"
              name="amount1"
              rules={[{ required: true, message: 'Enter an amount' }]}
              extra="Should be a number between 0 and 100"
            >
              <InputNumber
                ref={labelInput}
                disabled={loading}
                max={100}
                min={0}
                step={1}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="Amount 2"
              name="amount2"
              rules={[{ required: true, message: 'Enter an amount' }]}
              extra="Should be a number between 0 and 100"
            >
              <InputNumber
                disabled={loading}
                max={100}
                min={0}
                step={1}
                style={{ width: '100%' }}
              />
            </Form.Item>
            {error && (
              <div
                className="server-error ant-form-item-has-error"
                style={{ marginTop: 16, textAlign: 'center' }}
              >
                <div className="ant-form-item-explain">{error}</div>
              </div>
            )}
          </Form>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default VerifyBankAccountModal;
