import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Space,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createCheckAction,
  updateCheckAction,
} from '../../../redux-store/checks-store';
import { generateUuid } from '../../../shared/utils';
import SelectAddress from '../../addresses/SelectAddress';
import SelectBankAccount from '../../bank-accounts/SelectBankAccount';
import useProfile from '../../Profile/use-profile';
import DeleteCheckModal from '../DeleteCheckModal';
import SendCheckModal from '../SendCheckModal';
import { createCheckMutation, updateCheckMutation } from './mutations';
import useCheck from './use-check';

function EditCheck() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [updateCheck] = useMutation(updateCheckMutation);
  const [createCheck] = useMutation(createCheckMutation);

  const { _id } = useParams();

  const checkResult = useCheck(_id);
  const { check } = checkResult;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const profile = useProfile();
  const labelInput = useRef(null);
  const afterSaveAction = useRef(null);
  const [sending, setSending] = useState(null);

  const handleSaveDraft = useCallback(() => {
    afterSaveAction.current = () => {
      message.success('Check saved successfully');
      history.push('/checks');
    };
    form.submit();
  }, [form, history]);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (check && !loading && !onlyOnce.current) {
      const { toAddress, fromAddress, bankAccount, ...theRest } = check;
      form.setFieldsValue({
        ...theRest,
        bankAccountId: bankAccount.id,
        toAddressId: toAddress.id,
        fromAddressId: fromAddress.id,
      });
      onlyOnce.current = true;
    }
  }, [form, check, loading]);

  const handleBack = useCallback(() => history.goBack(), [history]);

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      setError(null);
      try {
        let results;
        if (check) {
          const response = await updateCheck({
            variables: {
              check: {
                id: _id,
                ...values,
              },
            },
          });
          results = response.data.updateCheck;
          dispatch(updateCheckAction(results));
        } else {
          const response = await createCheck({
            variables: {
              check: {
                id: _id,
                owner: profile.uid,
                ...values,
              },
            },
          });
          results = response.data.createCheck;
          dispatch(createCheckAction(results));
        }
        if (afterSaveAction.current) {
          afterSaveAction.current(results);
        } else {
          setLoading(false);
          // setSending(results);
          history.push(`/check/confirm/${results.id}`);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    },
    [check, createCheck, updateCheck, _id, dispatch, profile, history],
  );

  const handleCreateNewAddress = useCallback(() => {
    history.push(`/address/edit/${generateUuid()}`, { mode: 'create' });
  }, [history]);

  const handleCreateNewBankAccount = useCallback(() => {
    history.push(`/bank-account/edit/${generateUuid()}`, { mode: 'create' });
  }, [history]);

  const { title, showDelete } = useMemo(() => {
    let creating;
    if (loading) {
      if (location.state.mode && location.state.mode === 'create') {
        creating = true;
      } else {
        creating = false;
      }
    } else if (!loading) {
      if (check) {
        creating = false;
      } else {
        creating = true;
      }
    }
    let _title;
    let _showDelete;
    if (creating) {
      _title = 'Send a new check';
      _showDelete = false;
    } else {
      _title = 'Edit Check';
      _showDelete = true;
    }
    return { title: _title, showDelete: _showDelete };
  }, [loading, check, location.state.mode]);

  return (
    <>
      <CSPage>
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to checks
            </Button>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="editCheck"
          form={form}
          style={{ maxWidth: 500 }}
          // onValuesChange={(changedValues, allValues) => {
          //   console.log(allValues);
          // }}
        >
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                max: 255,
                message: 'Must be less than 255 characters',
              },
            ]}
            extra="e.g., February's rent, Car payment ..."
          >
            <Input ref={labelInput} disabled={loading || checkResult.loading} />
          </Form.Item>
          <Form.Item
            label="Bank Account"
            name="bankAccountId"
            rules={[
              { required: true, message: 'Please select a bank account' },
            ]}
          >
            <SelectBankAccount
              disabled={loading || checkResult.loading}
              createNewBankAccount={handleCreateNewBankAccount}
            />
          </Form.Item>
          <Form.Item
            label="From Address"
            name="fromAddressId"
            rules={[{ required: true, message: 'Please select an address' }]}
          >
            <SelectAddress
              disabled={loading || checkResult.loading}
              createNewAddress={handleCreateNewAddress}
            />
          </Form.Item>
          <Form.Item
            label="To Address"
            name="toAddressId"
            rules={[{ required: true, message: 'Please select an address' }]}
          >
            <SelectAddress
              disabled={loading || checkResult.loading}
              createNewAddress={handleCreateNewAddress}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter an amount',
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              disabled={loading || checkResult.loading}
              style={{ width: '100%' }}
              min={0}
              max={10000}
            />
          </Form.Item>
          <Form.Item
            label="Memo"
            name="memo"
            rules={[
              {
                max: 40,
                message: 'Must be less than 40 characters',
              },
            ]}
            extra="Notes to be written on the memo line (less than 40 characters)"
          >
            <Input ref={labelInput} disabled={loading || checkResult.loading} />
          </Form.Item>
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                key="cancel"
                onClick={handleBack}
                htmlType="button"
                type="text"
                size="small"
                disabled={loading || checkResult.loading}
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: -7 }}
              >
                Cancel
              </Button>
              <Space>
                <Button
                  key="send"
                  type="default"
                  loading={loading}
                  htmlType="button"
                  onClick={handleSaveDraft}
                >
                  Save Draft
                </Button>
                <Button
                  key="send"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Send Check
                </Button>
              </Space>
            </Space>
          </Form.Item>
          {error && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{error}</div>
            </div>
          )}
          {showDelete && (
            <>
              <Divider />
              <div className="delete-box">
                <Space align="center">
                  <Button
                    onClick={() => setDeleting(check)}
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                  >
                    Delete Check
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Form>
        <div style={{ minHeight: 300 }} />
        <DeleteCheckModal setCheck={setDeleting} check={deleting} />
        <SendCheckModal setCheck={setSending} check={sending} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditCheck;
