import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteAddressAction } from '../../redux-store/addresses-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

const deleteAddressMutation = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(id: $id)
  }
`;

function DeleteAddressModal({ address, setAddress }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteAddress] = useMutation(deleteAddressMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteAddress({
        variables: {
          id: address.id,
        },
      });
      dispatch(deleteAddressAction(address.id));
      message.success('Address deleted successfully');
      setAddress(null);
      history.goBack();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [address, deleteAddress, setAddress, dispatch, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setAddress(null);
    }
  }, [setAddress, loading]);

  return (
    <YesNoModal
      title="Delete Bank Account"
      question={`Are you sure you want to delete your bank account "${
        address && address.label
      }"?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!address}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteAddressModal;
