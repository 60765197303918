import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Drawer,
  Layout,
  Menu,
  notification,
  PageHeader,
  Typography,
} from 'antd';
import {
  BankOutlined,
  CheckOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  FileOutlined,
  HomeOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
  ProfileOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../shared/auth-context';
import logo from '../assets/logo-white.svg';
import logoBlack from '../assets/logo-black.svg';
import squareLogo from '../assets/square-logo.svg';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useWindowDimensions } from '../shared/window-dimensions';
import useResponsiveMode from '../shared/use-reponsive-mode';
import { shallowEqual, useSelector } from 'react-redux';

const { Sider } = Layout;
const { Title } = Typography;

function MainMenu({ currentItem, onMenuClick }) {
  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[currentItem]}
      onClick={onMenuClick}
      className="main-nav-menu"
    >
      {process.env.REACT_APP_ENV == 'dev' && <p>DEVELOPMENT</p>}
      <Menu.Item key="home" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="checks" icon={<CheckOutlined />}>
        Checks
      </Menu.Item>
      <Menu.Item key="payments" icon={<DollarCircleOutlined />}>
        Payments
      </Menu.Item>
      <Menu.Item key="billing" icon={<LockOutlined />}>
        Billing
      </Menu.Item>
      <Menu.Item key="account" icon={<ProfileOutlined />}>
        Account
      </Menu.Item>
      {/* <Menu.Item key="bank-accounts" icon={<BankOutlined />}>
        Bank Accounts
      </Menu.Item> */}
      {/* <Menu.Item key="addresses" icon={<EnvironmentOutlined />}>
        Addresses
      </Menu.Item> */}
      <div className="spacer" />
      <Menu.Item
        key="sign-out"
        icon={<LogoutOutlined />}
        style={{ marginTop: 'auto' }}
      >
        Sign Out
      </Menu.Item>
    </Menu>
  );
}

function MainSider({ collapsed, setCollapsed, children }) {
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={80}
      onBreakpoint={(broken) => {
        setCollapsed(collapsed || broken);
      }}
      onCollapse={(_collapsed) => {
        setCollapsed(collapsed === undefined ? false : _collapsed);
      }}
      theme="light"
      width={300}
      collapsible
      collapsed={collapsed}
      className="main-nav-menu-slider"
    >
      <div className="logo" style={{ width: collapsed ? 80 : undefined }}>
        {!collapsed && (
          <img className="logo-img" src={logo} alt="Check Supply Logo" />
        )}
        {collapsed && (
          <img
            className="square-logo-img"
            src={squareLogo}
            alt="Check Supply Logo"
          />
        )}
      </div>
      {children}
      <style jsx>{`
        .logo {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #f0f0f0;
        }
        .square-logo-img {
          height: 56px;
          padding: 16px;
        }
        .logo-img {
          height: 56px;
        }
        .spacer {
          margin: auto;
        }
      `}</style>
    </Sider>
  );
}

function MainNavMenu() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(undefined);
  const history = useHistory();
  const currentItem = useMemo(() => {
    const path = location.pathname;
    if (path === '/home') {
      return 'home';
    } else if (path === '/checks') {
      return 'checks';
    } else if (path === '/payments') {
      return 'payments';
    } else if (path === '/account') {
      return 'account';
    } else if (path === '/bank-accounts') {
      return 'bank-accounts';
    } else if (path === '/addresses') {
      return 'addresses';
    } else if (path === '/billing') {
      return 'billing';
    }
    return 'home';
  }, [location]);
  const onMenuClick = useCallback(
    ({ item, key, keyPath, domEvent }) => {
      if (key === 'sign-out') {
        authContext.signOut();
        history.push('/sign-in');
        notification.open({
          message: 'Success',
          description: `Successfully signed out!`,
        });
      } else if (key === 'home') {
        history.push('/home');
      } else if (key === 'checks') {
        history.push('/checks');
      } else if (key === 'payments') {
        history.push('/payments');
      } else if (key === 'account') {
        history.push('/account');
      } else if (key === 'bank-accounts') {
        history.push('/bank-accounts');
      } else if (key === 'addresses') {
        history.push('/addresses');
      } else if (key === 'billing') {
        history.push('/billing');
      }
    },
    [authContext, history],
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  if (responsiveMode === 'mobile') {
    return (
      <>
        <Layout.Header
          className="main-nav-header"
          style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <img src={logoBlack} alt="Check Supply Logo" />
          <Button
            key="1"
            type="primary"
            icon={<MenuOutlined onClick={() => setDrawerVisible(true)} />}
          />
        </Layout.Header>
        <div style={{ height: 72 }} />
        <Drawer
          title="Check Supply"
          placement="right"
          closable={true}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          className="main-nav-drawer"
        >
          <MainMenu onMenuClick={onMenuClick} currentItem={currentItem} />
        </Drawer>
        <style jsx global>{`
          .main-nav-menu {
            height: calc(100% - 80px);
          }
          .main-nav-menu-slider .ant-layout-sider-trigger {
            border-right: 1px solid #f0f0f0;
          }
          .main-nav-header {
            background-color: black;
            height: unset;
            padding-left: 0;
            padding-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .main-nav-drawer .ant-drawer-body {
            padding: 0px;
          }
        `}</style>
      </>
    );
  }
  return (
    <MainSider collapsed={collapsed} setCollapsed={setCollapsed}>
      <MainMenu onMenuClick={onMenuClick} currentItem={currentItem} />
    </MainSider>
  );
}

export default MainNavMenu;
