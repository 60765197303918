import { Button, Form, Select, Spin, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import DisplayAddress from '../../components/DisplayAddress';
import settings from '../../settings';
import { useAddresses } from './ListAddresses/use-addresses';

const { Text } = Typography;

function SelectAddress({ disabled, createNewAddress, value, onChange }) {
  const { error, loading, addresses } = useAddresses(settings.pageSize);
  const handleChange = useCallback(
    (_value, option) => {
      if (option.dataValue === 'create-new') {
        createNewAddress();
      }
      onChange(_value);
    },
    [createNewAddress, onChange],
  );
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={handleChange}
      placeholder="Select an address"
      value={value}
    >
      {loading && (
        <Select.Option value={null}>
          <Spin spinning={loading} style={{ margin: 8 }} />
        </Select.Option>
      )}
      {!loading && error && (
        <Select.Option value={null}>
          <Text type="danger">{error.message}</Text>
        </Select.Option>
      )}
      {!loading &&
        addresses.map((address) => (
          <Select.Option
            key={address.id}
            value={address.id}
            title={address.name}
          >
            <div className="address-option">
              {address.label && (
                <div>
                  <Text>{address.label}</Text>
                </div>
              )}
              <div>
                <Text>{address.name}</Text>
              </div>
              <DisplayAddress address={address} type="secondary" />
            </div>
          </Select.Option>
        ))}
      <Select.Option value={null} dataValue="create-new">
        Create new address
      </Select.Option>
    </Select>
  );
}

export default SelectAddress;
