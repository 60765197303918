import React from 'react';

function CSPage({ children }) {
  return (
    <div className="page">
      <div className="container">{children}</div>
      <style jsx>{`
        .page {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          flex-direction: column;
          position: relative;
          height: 100vh;
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 55px;
          padding-right: 55px;
        }
        @media screen and (max-width: 992px) {
          .page {
            height: calc(100vh - 72px);
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 55px;
            padding-right: 55px;
          }
        }
        @media screen and (max-width: 600px) {
          .page {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        .container {
          max-width: 800px;
        }
      `}</style>
    </div>
  );
}

export default CSPage;
