import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  message,
  Form,
  Input,
  Button,
  Typography,
  Spin,
  Space,
  Select,
} from 'antd';
import useBankAccount from './use-bank-account';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createBankAccountAction,
  updateBankAccountAction,
} from '../../../redux-store/bank-accounts-store';
import useProfile from '../../Profile/use-profile';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';

const { Title, Text } = Typography;

const createBankAccountMutation = gql`
  mutation createBankAccount($bankAccount: BankAccountCreateInput!) {
    createBankAccount(bankAccount: $bankAccount) {
      id
      owner
      label
      bankName
      accountType
      signatory
      verificationStatus
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

const updateBankAccountMutation = gql`
  mutation updateBankAccount($bankAccount: BankAccountUpdateInput!) {
    updateBankAccount(bankAccount: $bankAccount) {
      id
      owner
      label
      bankName
      accountType
      signatory
      verificationStatus
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

const EditBankAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { _id } = useParams();
  const [updateBankAccount] = useMutation(updateBankAccountMutation);
  const [createBankAccount] = useMutation(createBankAccountMutation);
  const history = useHistory();

  const bankAccountResult = useBankAccount(_id);
  const { bankAccount } = bankAccountResult;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const profile = useProfile();

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      setError(null);
      try {
        if (bankAccount) {
          const { label } = values;
          const result = await updateBankAccount({
            variables: {
              bankAccount: {
                id: _id,
                label,
              },
            },
          });
          dispatch(updateBankAccountAction(result.data.updateBankAccount));
          message.success('Bank account updated successfully');
        } else {
          const result = await createBankAccount({
            variables: {
              bankAccount: {
                id: _id,
                owner: profile.uid,
                ...values,
              },
            },
          });
          dispatch(createBankAccountAction(result.data.createBankAccount));
          message.success('Bank account created successfully');
        }
        history.push('/bank-accounts');
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [
      bankAccount,
      createBankAccount,
      updateBankAccount,
      _id,
      history,
      dispatch,
      profile,
    ],
  );

  const nameInput = useRef(null);

  const onlyOnce = useRef(false);
  useLayoutEffect(() => {
    if (nameInput.current && !onlyOnce.current) {
      nameInput.current.focus();
      onlyOnce.current = true;
    }
  }, [bankAccountResult]);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (bankAccount && !loading) {
      form.setFieldsValue(bankAccount);
    }
  }, [form, bankAccount, loading]);

  let pageTitle;
  if (bankAccount) {
    pageTitle = 'Edit Bank Account';
  } else {
    pageTitle = 'Connect a Bank Account';
  }

  return (
    <CSPage>
      <CSPageHeader
        title={pageTitle}
        backActions={[
          <Button
            key="back"
            type="text"
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
          >
            Back to bank accounts
          </Button>,
        ]}
      />
      <Spin spinning={bankAccountResult.loading} style={{ minHeight: 200 }}>
        {!bankAccountResult.loading && (
          <Form
            name="bankAccount"
            onFinish={onSubmit}
            layout="vertical"
            id="editBankAccount"
            form={form}
            style={{ maxWidth: 500 }}
          >
            <Form.Item
              label="Label"
              name="label"
              rules={[{ required: true, message: 'Please enter a label' }]}
              extra="e.g., Checking, Savings ..."
            >
              <Input ref={nameInput} disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Routing Number"
              name="routingNo"
              rules={[
                {
                  required: !bankAccountResult.bankAccount,
                  message: 'Please enter a routing number',
                },
                {
                  pattern: /^\d{9}$/,
                  message: 'Please enter a valid routing number.',
                },
              ]}
            >
              <Input disabled={loading || !!bankAccountResult.bankAccount} />
            </Form.Item>
            <Form.Item
              label="Account Number"
              name="accountNo"
              rules={[
                {
                  required: !bankAccountResult.bankAccount,
                  message: 'Please enter an account number',
                },
                {
                  pattern: /^\d{8,12}$/,
                  message: 'Please enter a valid account number.',
                },
              ]}
            >
              <Input disabled={loading || !!bankAccountResult.bankAccount} />
            </Form.Item>
            <Form.Item
              label="Account Type"
              name="accountType"
              rules={[
                { required: true, message: 'Please choose an account type' },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                autocomplete="chrome-off"
                disabled={loading || !!bankAccountResult.bankAccount}
              >
                <Select.Option value="individual">Individual</Select.Option>
                <Select.Option value="company">Company</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Signatory"
              name="signatory"
              rules={[{ required: true, message: 'Please supply a signatory' }]}
              extra="The name used to sign checks sent from this bank account"
            >
              <Input disabled={loading || !!bankAccountResult.bankAccount} />
            </Form.Item>
            <div style={{ height: 16 }} />
            <div style={{ height: 16 }} />
            <Form.Item>
              <Space
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  key="cancel"
                  onClick={onBack}
                  htmlType="button"
                  type="text"
                  size="small"
                  disabled={loading}
                  icon={<ArrowLeftOutlined />}
                  style={{ marginLeft: -7 }}
                >
                  Cancel
                </Button>
                <Button
                  key="send"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Save Bank Account
                </Button>
              </Space>
            </Form.Item>
            {error && (
              <div
                className="server-error ant-form-item-has-error"
                style={{ marginTop: 16, textAlign: 'center' }}
              >
                <div className="ant-form-item-explain">{error}</div>
              </div>
            )}
          </Form>
        )}
      </Spin>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </CSPage>
  );
};

export default EditBankAccount;
