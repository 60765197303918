import React, { useCallback, useState } from 'react';
import { Typography, Button, Card, Space, Spin, Skeleton } from 'antd';
import CSPageHeader from '../../../components/CSPageHeader';
import CSPage from '../../../components/CSPage';
import { BankOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useBankAccounts } from './use-bank-accounts';
import { useHistory } from 'react-router-dom';
import { capitalize, generateUuid } from '../../../shared/utils';
import { shallowEqual, useSelector } from 'react-redux';
import BankAccountVerificationStatus from './BankAccountVerificationStatus';
import settings from '../../../settings';

const { Title, Text } = Typography;

function BankAccountCard({ bankAccount, handleUpdate }) {
  return (
    <Button
      className="bank-account-card"
      onClick={handleUpdate(bankAccount.id)}
    >
      <div className="top-line">
        <Text type="secondary">
          <BankOutlined style={{ fontSize: 24 }} />
        </Text>
        <BankAccountVerificationStatus
          status={bankAccount.verificationStatus}
        />
      </div>
      {bankAccount.label && (
        <div className="top-line">
          <Title level={3} style={{ margin: 0 }}>
            {bankAccount.label}
          </Title>
        </div>
      )}
      <Text>{bankAccount.signatory}</Text>
      <Text type="secondary">{bankAccount.bankName}</Text>
      <Text type="secondary">{capitalize(bankAccount.accountType)}</Text>
      <div style={{ flex: 1 }} />
      <style jsx>{`
        .top-line {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        }
      `}</style>
      <style jsx global>{`
        .bank-account-card {
          background: white;
          width: 350px;
          min-height: 190px;
          height: unset;
          border-radius: 5px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
          padding: 24px 28px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 24px;
        }
        @media screen and (max-width: 800px) {
          .bank-account-card {
            width: calc(100vw - 110px);
          }
        }
        @media screen and (max-width: 600px) {
          .bank-account-card {
            width: calc(100vw - 32px);
          }
        }
      `}</style>
    </Button>
  );
}

function NewBankAccountCard({ handleCreate }) {
  return (
    <Button className="new-bank-account-card" onClick={handleCreate}>
      <Space direction="vertical" className="inner">
        <PlusCircleOutlined style={{ fontSize: 24 }} />
        Add a new bank account
      </Space>
      <style jsx>{`
        .inner {
        }
      `}</style>
      <style jsx global>{`
        .new-bank-account-card {
          background: white;
          width: 350px;
          min-height: 190px;
          height: unset;
          border-radius: 5px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
          margin-bottom: 24px;
        }
        @media screen and (max-width: 800px) {
          .new-bank-account-card {
            width: calc(100vw - 110px);
          }
        }
        @media screen and (max-width: 600px) {
          .new-bank-account-card {
            width: calc(100vw - 32px);
          }
        }
      `}</style>
    </Button>
  );
}

function LoadingBankAccountCard() {
  return (
    <Button className="new-bank-account-card">
      <Skeleton title paragraph loading active />
      <style jsx>{`
        .inner {
        }
      `}</style>
      <style jsx global>{`
        .new-bank-account-card {
          background: white;
          width: 350px;
          min-height: 190px;
          height: unset;
          border-radius: 5px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
          margin-bottom: 24px;
        }
        @media screen and (max-width: 800px) {
          .new-bank-account-card {
            width: calc(100vw - 110px);
          }
        }
        @media screen and (max-width: 600px) {
          .new-bank-account-card {
            width: calc(100vw - 32px);
          }
        }
      `}</style>
    </Button>
  );
}

function ListBankAccounts() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);

  const handleCreate = useCallback(() => {
    const bankAccountId = generateUuid();
    history.push(`bank-account/edit/${bankAccountId}`, { mode: 'create' });
  }, [history]);

  const handleUpdate = useCallback(
    (bankAccountId) => () => {
      history.push(`bank-account/edit/${bankAccountId}`, { mode: 'update' });
    },
    [history],
  );

  const { error, loading, bankAccounts, refetch } = useBankAccounts(
    settings.pageSize,
    {},
  );

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <>
      <div className="headline">
        <Title level={3} style={{ fontWeight: 500, margin: 0 }}>
          Bank Accounts
        </Title>
      </div>
      {loading && (
        <Space
          className="body"
          size="large"
          direction="horizontal"
          style={{ flexWrap: 'wrap' }}
        >
          {[0, 1, 2].map((index) => (
            <LoadingBankAccountCard key={index} />
          ))}
        </Space>
      )}
      {!loading && (
        <Space size="large" direction="horizontal" style={{ flexWrap: 'wrap' }}>
          {bankAccounts.map((bankAccount) => (
            <BankAccountCard
              key={bankAccount.id}
              bankAccount={bankAccount}
              handleUpdate={handleUpdate}
            />
          ))}
          <NewBankAccountCard handleCreate={handleCreate} />
        </Space>
      )}
      {error && <Text type="danger">{error}</Text>}
      <style jsx>{`
        .headline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          height: 48px;
        }
      `}</style>
    </>
  );
}

export default ListBankAccounts;
