import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { updateCheckAction } from '../../redux-store/checks-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

const cancelCheckMutation = gql`
  mutation cancelCheck($id: ID!) {
    cancelCheck(id: $id) {
      id
      owner
      description
      toAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      fromAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      bankAccount {
        id
        label
        bankName
        accountType
        signatory
      }
      amount
      memo
      checkNumber
      expectedDeliveryDate
      mailType
      sendDate
      thumbnails {
        medium
      }
      trackingEvents {
        lobId
        name
        location
        time
      }
      currentStatus
      cancelled
      isDraft
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

function CancelCheckModal({ check, setCheck }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cancelCheck] = useMutation(cancelCheckMutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await cancelCheck({
        variables: {
          id: check.id,
        },
      });
      dispatch(updateCheckAction(result.data.cancelCheck));
      message.success('Check canceled successfully');
      setCheck(null);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [check, cancelCheck, dispatch, setCheck]);

  const onNo = useCallback(() => {
    if (!loading) {
      setCheck(null);
    }
  }, [setCheck, loading]);

  return (
    <YesNoModal
      title="Cancel Check"
      question={`Are you sure you want to cancel your check "${
        check &&
        (check.description ||
          check.memo ||
          (check.toAddress && check.toAddress.name))
      }"?`}
      yesText="Cancel Check"
      noText="Go Back"
      onYes={onYes}
      onNo={onNo}
      visible={!!check}
      loading={loading}
      error={error}
      textAlign="left"
    />
  );
}

export default CancelCheckModal;
