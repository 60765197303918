import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Skeleton, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import StatusBalls from '../../components/StatusBalls';
import settings from '../../settings';
import { getCheckStatus, Statuses } from '../../shared/get-check-status';
import CancelCheckModal from './CancelCheckModal';
import useCheck from './EditCheck/use-check';
import moneyFace from '../../assets/money-face.jpg';
import DisplayAddressWithName from '../../components/DisplayAddressWithName';
import Details from '../../components/Details';

const { Title, Text } = Typography;

function CheckDetails() {
  const { _id } = useParams();
  const history = useHistory();
  const [cancelling, setCancelling] = useState(null);

  const checkResult = useCheck(_id);
  const { error, loading, check } = checkResult;
  const title = useMemo(() => {
    if (check) {
      return `Check #${check.checkNumber}`;
    }
    return 'Check Details';
  }, [check]);

  const locale = useSelector((store) => store.locale, shallowEqual);

  const details = useMemo(() => {
    const _details = [
      {
        id: 'from',
        label: 'From',
        content: null,
      },
      {
        id: 'to',
        label: 'Payee',
        content: null,
      },
      {
        id: 'amount',
        label: 'Amount',
        content: null,
      },
      {
        id: 'memo',
        label: 'Memo',
        content: null,
      },
      {
        id: 'createdAt',
        label: 'Date Created',
        content: null,
      },
      {
        id: 'expectedDelivery',
        label: 'Expected Delivery',
        content: null,
      },
      {
        id: 'sendDate',
        label: 'Send Date',
        content: null,
      },
      {
        id: 'bankAccount',
        label: 'Bank Account',
        content: null,
      },
    ];
    if (check) {
      _details[0].content = (
        <DisplayAddressWithName address={check.fromAddress} />
      );
      _details[1].content = (
        <DisplayAddressWithName address={check.toAddress} />
      );
      _details[2].content = `$${check.amount.toFixed(2)}`;
      _details[3].content = check.memo || null;
      _details[4].content = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }).format(new Date(check.createdAt));
      _details[5].content = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }).format(new Date(check.expectedDeliveryDate));
      _details[6].content = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }).format(new Date(check.sendDate));
      _details[7].content = check.bankAccount.label || bankAccount.bankName;
    }
    return _details;
  }, [check, locale]);

  const checkStatus = useMemo(() => {
    return check && getCheckStatus(check);
  }, [check]);

  const topSentence = useMemo(() => {
    if (!checkStatus) return undefined;
    switch (checkStatus) {
      case 'received':
        return 'We’ve received your request and will begin printing your check in five minutes. Once printing begins you’ll no longer be able to cancel your check.';
      case 'printing':
        return 'Good news! We’re printing your check now and will be mailing it soon.';
      case 'mailed':
        return 'Good news! Your check has been mailed and is on its way.';
      case 'in-transit':
        return 'Good news! Your check has been mailed and is on its way.';
      case 'delivered':
        return 'Your check has been delivered.';
      case 'cancelled':
        return 'Your check has been cancelled and will not be sent.';
      default:
        return `Check status: ${checkStatus}`;
    }
  }, [checkStatus]);

  const [statuses, statusColor] = useMemo(() => {
    if (checkStatus === 'cancelled') {
      return [
        [
          ...Statuses,
          {
            label: 'Cancelled',
            value: 'cancelled',
          },
        ],
        settings.colors.red,
      ];
    }
    return [Statuses, undefined];
  }, [checkStatus]);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  return (
    <>
      <CSPage>
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={() => history.push('/checks')}
              icon={<ArrowLeftOutlined />}
            >
              Back to checks
            </Button>,
          ]}
        />
        <div className="status-text">
          <Skeleton active loading={!topSentence} title={false} paragraph />
          {topSentence && <Text style={{ fontSize: 18 }}>{topSentence}</Text>}
        </div>
        <div className="headline">
          <Title level={3} style={{ fontWeight: 500, margin: 0 }}>
            Status
          </Title>
          {checkStatus === 'received' && (
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              style={{ marginRight: -15 }}
              onClick={() => setCancelling(check)}
            >
              Cancel Check
            </Button>
          )}
        </div>
        <Card
          style={{
            boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: 5,
          }}
          className="status-card"
        >
          <StatusBalls
            statuses={statuses}
            currentStatus={checkStatus}
            color={statusColor}
          />
        </Card>
        <div className="headline">
          <Title level={3} style={{ fontWeight: 500, margin: 0 }}>
            Details
          </Title>
        </div>
        <Card
          style={{
            marginBottom: 55,
            boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: 5,
            overflow: 'hidden',
            border: 0,
          }}
          className="details-card"
        >
          <Row style={{ flexWrap: 'wrap' }}>
            <Col
              md={8}
              sm={24}
              style={{
                backgroundColor: settings.colors.gray,
                minHeight: responsiveMode === 'mobile' ? 240 : 360,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                backgroundImage: `url(${moneyFace})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Col md={16} sm={24}>
              <Details details={details} />
            </Col>
          </Row>
        </Card>
      </CSPage>
      <CancelCheckModal check={cancelling} setCheck={setCancelling} />
      <style jsx>{`
        .status-text {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 24px;
          }
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 20px;
          }
        }
        .headline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          height: 48px;
        }
        .detail {
          width: 50%;
          margin-bottom: 8px;
        }
        @media screen and (max-width: 600px) {
          .detail {
            width: 100%;
          }
        }
        .detail-content {
          font-size: 14px;
        }
      `}</style>
      <style jsx global>{`
        .details-card .ant-card-body {
          padding: 0px;
        }
        .detail-label {
          text-transform: uppercase;
          font-size: 11px;
          color: #858585;
          margin-bottom: 8px;
        }
        .status-card {
          margin-bottom: 55px;
        }
        @media screen and (max-width: 992px) {
          .status-card {
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default CheckDetails;
