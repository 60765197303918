import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, gql, useApolloClient } from '@apollo/client';
import { readAddressesAction } from '../../../redux-store/addresses-store';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import apolloClient from '../../../apollo-client';

export const allAddressesQuery = gql`
  query AllAddresses($filters: AddressFilters) {
    allAddresses(filters: $filters) {
      edges {
        node {
          id
          owner
          label
          name
          company
          address1
          address2
          city
          state
          zipCode
          country
          verificationStatus
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export function useAddresses(first, filters) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refetches, setRefetches] = useState(0);
  const _refetches = useRef(-1);

  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        const result = await apolloClient.query({
          query: allAddressesQuery,
          variables: {
            first,
            filters,
          },
          fetchPolicy: 'network-only',
        });
        if (
          result &&
          result.data &&
          result.data.allAddresses &&
          result.data.allAddresses.edges
        ) {
          // const bas = [];
          // for (let i = 0; i < 1000; i += 1) {
          //   const b = { ...result.data.allAddresses.edges[0].node, id: i };
          //   bas.push(b);
          // }
          dispatch(
            readAddressesAction(
              result.data.allAddresses.edges.map((edge) => edge.node),
            ),
          );
          // dispatch(readAddressesAction(bas));
        }
      } catch (err) {
        setError(err.message);
      }
      _refetches.current = refetches;
      setLoading(false);
    }
    if (_refetches.current !== refetches) {
      doAsyncStuff();
    }
  }, [dispatch, filters, refetches, first]);

  const addresses = useSelector(
    (store) =>
      Object.values(store.addresses)
        .filter((b) => b)
        .sort((a, b) => a.name.localeCompare(b.name)),
    shallowEqual,
  );

  return {
    addresses,
    loading,
    error,
    refetch: () => setRefetches(refetches + 1),
  };
}
