import { Space, Spin, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const { Title, Text } = Typography;

const YesNoModal = ({
  title,
  question,
  contents = null,
  yesText,
  yesIcon,
  noText,
  onYes,
  onNo,
  visible,
  loading = false,
  error = null,
  closable = true,
  destroyOnClose = true,
  maskClosable = true,
  textAlign = 'center',
}) => {
  return (
    <>
      <Modal
        title={title}
        visible={visible}
        closable={closable}
        destroyOnClose={destroyOnClose}
        maskClosable={maskClosable}
        cancelText={noText}
        cancelButtonProps={
          noText === null
            ? { style: { display: 'none' } }
            : { disabled: loading }
        }
        onCancel={onNo}
        okText={yesText}
        okButtonProps={{ icon: yesIcon }}
        onOk={onYes}
        confirmLoading={loading}
      >
        <Spin spinning={loading}>
          <Space
            direction="vertical"
            align={{ left: 'start', center: 'center', right: 'end' }[textAlign]}
            style={{ textAlign }}
          >
            {question && <Text>{question}</Text>}
            {contents}
            {error && (
              <div
                className="server-error ant-form-item-has-error"
                style={{ marginTop: 16 }}
              >
                <div className="ant-form-item-explain">{error}</div>
              </div>
            )}
          </Space>
        </Spin>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default YesNoModal;
