import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import useScript from '../../../shared/use-script';
import useProfile from '../../Profile/use-profile';
import apolloClient from '../../../apollo-client';
import useQueryParams from '../../../shared/use-query-params';
import useStripeSession from './use-stripe-session';

const { Title, Text } = Typography;

const BuyChecksSuccess = () => {
  const history = useHistory();
  const query = useQueryParams();

  const { loading, error, payment } = useStripeSession(query.get('session_id'));

  const onBack = useCallback(() => {
    history.push('/account');
  }, [history]);

  const numChecks = useMemo(() => {
    if (payment) {
      return payment.lineItems.reduce((prev, curr) => {
        return curr.code === 'SINGLE_CHECK' ? prev + curr.quantity : prev;
      }, 0);
    }
    return undefined;
  }, [payment]);

  return (
    <CSPage>
      <CSPageHeader
        title="Success"
        backActions={[
          <Button
            key="back"
            type="text"
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
          >
            Back to account
          </Button>,
        ]}
      />
      <Card
        style={{
          marginBottom: 55,
          boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.05)',
          borderRadius: 5,
          overflow: 'hidden',
          border: 0,
        }}
        className="details-card"
      >
        {loading && <Skeleton active loading paragraph title />}
        {!loading && (
          <Space direction="vertical">
            <Text
              style={{ fontSize: 18 }}
            >{`Congratulations!  You've purchased ${numChecks} checks.`}</Text>
            <Text style={{ fontSize: 18 }}>
              Your purchase will be reflected in your account as soon as your
              payment is processed.
            </Text>
            <div style={{ height: 8 }} />
            <Button
              icon={<ArrowLeftOutlined />}
              type="primary"
              onClick={onBack}
            >
              Go to My Account
            </Button>
          </Space>
        )}
        {error && <Text type="danger">{error}</Text>}
      </Card>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </CSPage>
  );
};

export default BuyChecksSuccess;
