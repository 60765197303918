import { gql, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updatePaymentAction } from '../../../redux-store/payments-store';

export const postStripeCheckoutMutation = gql`
  mutation PostStripeCheckout($sessionId: ID!) {
    postStripeCheckout(sessionId: $sessionId) {
      id
      owner
      lineItems {
        code
        label
        currency
        unitPrice
        images
        quantity
        discountCode
        metadata
      }
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export default function useStripeSession(sessionId) {
  const [postStripeCheckout] = useMutation(postStripeCheckoutMutation);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const once = useRef(true);

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        const result = await postStripeCheckout({
          variables: {
            sessionId,
          },
        });
        if (result && result.data && result.data.postStripeCheckout) {
          dispatch(updatePaymentAction(result.data.postStripeCheckout));
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    }
    if (once.current) {
      doAsyncStuff();
      once.current = false;
    }
  }, [sessionId, dispatch, postStripeCheckout]);

  const payment = useSelector(
    (store) => store.payments[sessionId],
    shallowEqual,
  );

  return {
    payment,
    loading,
    error,
  };
}
