import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

const DisplayAddressWithName = ({ address }) => (
  <>
    <div>
      <Text>{address.name}</Text>
    </div>
    {address.company && (
      <div>
        <Text>{address.company}</Text>
      </div>
    )}
    <div>
      <Text>{address.address1}</Text>
    </div>
    {address.address2 && (
      <div>
        <Text>{address.address1}</Text>
      </div>
    )}
    <div>
      <Text>{`${address.city}, ${address.state} ${address.zipCode}`}</Text>
    </div>
  </>
);

export default DisplayAddressWithName;
