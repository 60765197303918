import React from 'react';
import { Typography, Button } from 'antd';

const { Title } = Typography;

function CSPageHeader({ topActions = null, backActions = null, title }) {
  return (
    <div className="header">
      <div className="back-actions">{backActions}</div>
      <Title className="cs-header-title" style={{ marginBottom: 8 }}>
        {title}
      </Title>
      <div className="top-actions">{topActions}</div>
      <style jsx>{`
        .header {
          margin-bottom: 32px;
        }
        .back-actions {
          height: 50px;
          margin-left: -15px;
        }
        .top-actions {
          margin-left: -15px;
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
      <style jsx global>{`
        .cs-header-title {
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
    </div>
  );
}

export default CSPageHeader;
