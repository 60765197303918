import { Form, Select, Spin, Typography } from 'antd';
import React, { useCallback } from 'react';
import settings from '../../settings';
import { useBanksAccounts } from './ListBankAccounts/use-banks-accounts';

const { Text } = Typography;

function SelectBankAccount({
  disabled,
  createNewBankAccount,
  value,
  onChange,
}) {
  const { error, loading, bankAccounts } = useBanksAccounts(settings.pageSize, {
    verificationStatus: 'verified',
  });
  const handleChange = useCallback(
    (_value, option) => {
      if (option.dataValue === 'create-new') {
        createNewBankAccount();
      }
      onChange(_value);
    },
    [createNewBankAccount, onChange],
  );
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={handleChange}
      placeholder="Select a bank account"
      value={value}
    >
      {loading && (
        <Select.Option value={null}>
          <Spin spinning={loading} style={{ margin: 8 }} />
        </Select.Option>
      )}
      {!loading && error && (
        <Select.Option value={null}>
          <Text type="danger">{error}</Text>
        </Select.Option>
      )}
      {!loading &&
        bankAccounts.map((bankAccount) => (
          <Select.Option
            key={bankAccount.id}
            value={bankAccount.id}
            label={bankAccount.label || bankAccount.bankName}
          >
            <div className="bank-account-option">
              {bankAccount.label && (
                <div>
                  <Text>{bankAccount.label}</Text>
                </div>
              )}
              <div>
                <Text>{bankAccount.signatory}</Text>
              </div>
              <div>
                <Text type="secondary">{bankAccount.bankName}</Text>
              </div>
              <div>
                <Text type="secondary">{bankAccount.accountType}</Text>
              </div>
            </div>
          </Select.Option>
        ))}
      <Select.Option value={null} dataValue="create-new">
        Create new bank account
      </Select.Option>
    </Select>
  );
}

export default SelectBankAccount;
