import {
  ArrowLeftOutlined,
  CheckOutlined,
  DeleteOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row, Skeleton, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import StatusBalls from '../../components/StatusBalls';
import settings from '../../settings';
import { getCheckStatus, Statuses } from '../../shared/get-check-status';
import CancelCheckModal from './CancelCheckModal';
import useCheck from './EditCheck/use-check';
import moneyFace from '../../assets/money-face.jpg';
import DisplayAddressWithName from '../../components/DisplayAddressWithName';
import { useCallback } from 'react';
import useProfile from '../Account/Profile/use-profile';
import SendCheckModal from './SendCheckModal';

const { Title, Text } = Typography;

const BankAccount = ({ bankAccount }) => (
  <>
    {bankAccount.label && (
      <div>
        <Text>{bankAccount.label}</Text>
      </div>
    )}
    {bankAccount.bankName && (
      <div>
        <Text>{bankAccount.bankName}</Text>
      </div>
    )}
    {bankAccount.signatory && (
      <div>
        <Text>{bankAccount.signatory}</Text>
      </div>
    )}
  </>
);

function ConfirmCheckAndPay() {
  const { _id } = useParams();
  const history = useHistory();
  const [sending, setSending] = useState(null);

  const checkResult = useCheck(_id);
  const { error, loading, check } = checkResult;
  const title = useMemo(() => {
    if (check) {
      return `Check #${check.checkNumber}`;
    }
    return 'Check Details';
  }, [check]);

  const locale = useSelector((store) => store.locale, shallowEqual);

  const details = useMemo(() => {
    const _details = [
      {
        id: 'from',
        label: 'From',
        content: null,
      },
      {
        id: 'to',
        label: 'Payee',
        content: null,
      },
      {
        id: 'amount',
        label: 'Amount',
        content: null,
      },
      {
        id: 'memo',
        label: 'Memo',
        content: null,
      },
      {
        id: 'bankAccount',
        label: 'Bank Account',
        content: null,
      },
      {
        id: 'sendDate',
        label: 'Send Date',
        content: null,
      },
    ];
    if (check) {
      _details[0].content = (
        <DisplayAddressWithName address={check.fromAddress} />
      );
      _details[1].content = (
        <DisplayAddressWithName address={check.toAddress} />
      );
      _details[2].content = `$${check.amount.toFixed(2)}`;
      _details[3].content = check.memo || null;
      _details[4].content = <BankAccount bankAccount={check.bankAccount} />;
      _details[5].content = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }).format(new Date(check.updatedAt));
    }
    return _details;
  }, [check, locale]);

  const checkStatus = useMemo(() => {
    return check && getCheckStatus(check);
  }, [check]);

  const topSentence = useMemo(() => {
    if (!checkStatus) return undefined;
    switch (checkStatus) {
      case 'received':
        return 'We’ve received your request and will begin printing your check in five minutes. Once printing begins you’ll no longer be able to cancel your check.';
      case 'printing':
        return 'Good news! We’re printing your check now and will be mailing it soon.';
      case 'mailed':
        return 'Good news! Your check has been mailed and is on its way.';
      case 'in-transit':
        return 'Good news! Your check has been mailed and is on its way.';
      case 'delivered':
        return 'Your check has been delivered.';
      case 'cancelled':
        return 'Your check has been cancelled and will not be sent.';
      default:
        return `Check status: ${checkStatus}`;
    }
  }, [checkStatus]);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const profile = useProfile();

  return (
    <>
      <CSPage>
        <CSPageHeader
          title="Confirm &amp; Send Check"
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back
            </Button>,
          ]}
        />
        <div className="headline">
          <Title level={3} style={{ fontWeight: 500, margin: 0 }}>
            Check Details
          </Title>
        </div>
        <Card
          style={{
            marginBottom: 48,
            boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: 5,
            overflow: 'hidden',
            border: 0,
          }}
          className="details-card"
        >
          <Row style={{ flexWrap: 'wrap' }}>
            <Col
              sm={24}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                padding: '24px 32px',
              }}
            >
              {details.map((detail) => (
                <div className="detail" key={detail.id}>
                  <div>
                    <Text className="detail-label">{detail.label}</Text>
                  </div>
                  <div className="detail-content">
                    <Skeleton
                      active
                      loading={!detail.content}
                      title={false}
                      paragraph
                    />
                    {detail.content}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Card>
        <div className="status-text">
          <Text style={{ fontSize: 18 }}>Your check is ready to send!</Text>
        </div>
        {profile.checksAvailable && profile.checksAvailable > 0 ? (
          <>
            <div className="status-text">
              <Text
                style={{ fontSize: 18 }}
              >{`You currently have ${profile.checksAvailable} checks in your checkbook. This will decrease your available checks by 1.`}</Text>
            </div>

            <Space>
              <Button
                type="primary"
                onClick={() => setSending(check)}
                icon={<SendOutlined />}
              >
                Send
              </Button>
            </Space>
          </>
        ) : (
          <>
            <div className="status-text">
              <Text
                style={{ fontSize: 18 }}
              >{`You do not currently have any checks available to send.`}</Text>
            </div>
            <div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => history.push('/checks/buy')}
                  icon={<CheckOutlined />}
                >
                  Purchase Checks
                </Button>
              </Space>
            </div>
          </>
        )}
        <SendCheckModal setCheck={setSending} check={sending} />
      </CSPage>
      <style jsx>{`
        .status-text {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 24px;
          }
        }
        @media screen and (max-width: 992px) {
          .status-text {
            margin-bottom: 20px;
          }
        }
        .headline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          height: 48px;
        }
        .detail {
          width: 50%;
          margin-bottom: 8px;
        }
        @media screen and (max-width: 600px) {
          .detail {
            width: 100%;
          }
        }
        .detail-content {
          font-size: 14px;
        }
      `}</style>
      <style jsx global>{`
        .details-card .ant-card-body {
          padding: 0px;
        }
        .detail-label {
          text-transform: uppercase;
          font-size: 11px;
          color: #858585;
          margin-bottom: 8px;
        }
        .status-card {
          margin-bottom: 55px;
        }
        @media screen and (max-width: 992px) {
          .status-card {
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default ConfirmCheckAndPay;
