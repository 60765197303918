import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, List, Space, Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FancyListPage from '../../../components/FancyListPage';
import { capitalize, generateUuid } from '../../../shared/utils';
import DeleteCheckModal from '../DeleteCheckModal';
import { useChecks } from './use-checks';

const { Title, Text } = Typography;

function ListItem({
  record,
  locale,
  setDeleting,
  style,
  responsiveMode,
  isLast,
  history,
}) {
  const buttonSize = responsiveMode === 'mobile' ? 'small' : 'middle';
  return (
    <List.Item key={record.id} style={{ ...style }} className="list-item">
      <List.Item.Meta
        title={(record.toAddress && record.toAddress.name) || 'Check'}
        description={
          <div>
            {record.description && (
              <div>
                <Text>{record.description}</Text>
              </div>
            )}
            {record.amount && (
              <div>
                <Text>{`$ ${record.amount.toFixed(2)}`}</Text>
              </div>
            )}
            {`Created ${Intl.DateTimeFormat(locale).format(
              new Date(record.createdAt),
            )}`}
          </div>
        }
      />
      <Space size="middle">
        <Space>
          <div>
            <Text>{capitalize(record.currentStatus.toLowerCase())}</Text>
          </div>
          <Tooltip title="View">
            <Button
              onClick={() => history.push(`/check/details/${record.id}`)}
              icon={<EyeOutlined />}
              size={buttonSize}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              onClick={() =>
                history.push(`/check/edit/${record.id}`, { mode: 'update' })
              }
              icon={<EditOutlined />}
              size={buttonSize}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              onClick={() => setDeleting(record)}
              icon={<DeleteOutlined />}
              size={buttonSize}
            />
          </Tooltip>
        </Space>
      </Space>
      {!isLast && <div className="bottom-border" />}
      <style jsx>{`
        .bottom-border {
          position: absolute;
          bottom: 0;
          border-bottom: 1px solid #bdbdbd;
          left: 55px;
          right: 55px;
        }
        @media screen and (max-width: 992px) {
          .bottom-border {
            left: 55px;
            right: 55px;
          }
        }
        @media screen and (max-width: 600px) {
          .bottom-border {
            left: 16px;
            right: 16px;
          }
        }
      `}</style>
    </List.Item>
  );
}

function ListChecks() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);
  const [verifying, setVerifying] = useState(null);

  const { error, loading, checks, refetch } = useChecks({});

  const handleCreate = useCallback(() => {
    const checkId = generateUuid();
    history.push(`check/edit/${checkId}`, { mode: 'create' });
  }, [history]);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  const getListItemHeight = useCallback(
    () => (responsiveMode === 'mobile' ? 150 : 120),
    [responsiveMode],
  );

  return (
    <FancyListPage
      data={checks}
      getListItemHeight={getListItemHeight}
      topActionsHeight={60}
      loading={loading}
      error={error}
      title="Checks"
      renderListItem={({ index, style, isLast }) => (
        <ListItem
          record={checks[index]}
          style={style}
          setDeleting={setDeleting}
          locale={locale}
          responsiveMode={responsiveMode}
          isLast={isLast}
          history={history}
        />
      )}
      topActions={[
        <Button
          key="create"
          type="link"
          onClick={handleCreate}
          icon={<PlusOutlined />}
        >
          Send a new check
        </Button>,
      ]}
    >
      <DeleteCheckModal setCheck={setDeleting} check={deleting} />
    </FancyListPage>
  );
}

export default ListChecks;
