import React from 'react';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import settings from '../../../settings';

const { Text } = Typography;

function AddressVerificationStatus({ status }) {
  if (status === 'verified') {
    return (
      <Tooltip title="Bank account verified">
        <CheckCircleOutlined
          style={{ color: settings.colors.primary, fontSize: 24 }}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Bank account not verified">
      <ExclamationCircleOutlined
        style={{ color: settings.colors.warning, fontSize: 24 }}
      />
    </Tooltip>
  );
}

export default AddressVerificationStatus;
