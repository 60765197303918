import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

function DisplayAddress({ address, type }) {
  return (
    <div className="address">
      <Text type={type}>{address.address1}</Text>
      <br />
      {address.address2 && (
        <>
          <Text type={type}>{address.address2}</Text>
          <br />
        </>
      )}
      <Text type={type}>{`${address.city}, ${address.state}`}</Text>
      <br />
      <Text type={type}>{address.zipCode}</Text>
      <style jsx>{`
        .address {
          text-align: left;
        }
      `}</style>
    </div>
  );
}

export default DisplayAddress;
