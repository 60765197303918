export const READ_PAYMENTS = 'READ_PAYMENTS';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';

// eslint-disable-next-line no-shadow
export const readPaymentsAction = (payments) => ({
  type: READ_PAYMENTS,
  payload: {
    payments,
  },
});

export const createPaymentAction = (payment) => ({
  type: CREATE_PAYMENT,
  payload: {
    payment,
  },
});

export const updatePaymentAction = (payment) => ({
  type: UPDATE_PAYMENT,
  payload: {
    payment,
  },
});

export const deletePaymentAction = (paymentId) => ({
  type: DELETE_PAYMENT,
  payload: {
    paymentId,
  },
});

const initialPayments = [];

export function payments(state = initialPayments, action) {
  console.log('action', action);
  switch (action.type) {
    case READ_PAYMENTS: {
      const newState = { ...state };
      action.payload.payments.forEach((p) => {
        newState[p.id] = p;
      });
      return newState;
    }
    case CREATE_PAYMENT:
      return {
        ...state,
        [action.payload.payment.id]: action.payload.payment,
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        [action.payload.payment.id]: action.payload.payment,
      };
    case DELETE_PAYMENT: {
      const { paymentId } = action.payload;
      return { ...state, [paymentId]: undefined };
    }
    default:
      return state;
  }
}
