import { CheckOutlined } from '@ant-design/icons';
import { Timeline, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../settings';
import useResponsiveMode from '../shared/use-reponsive-mode';

const { Text } = Typography;

function StatusBalls({
  statuses,
  currentStatus,
  color = settings.colors.primary,
}) {
  const numGreenBalls = useMemo(() => {
    let statusIndex = -1;
    statuses.forEach((status, index) => {
      if (status.value === currentStatus) {
        statusIndex = index;
      }
    });
    return statusIndex + 1;
  }, [currentStatus, statuses]);
  const percent = 1 / (statuses.length - 1);
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  // if (responsiveMode === 'mobile') {
  //   return (
  //     <div className="status-timeline-container">
  //       <Timeline>
  //         {statuses.map((status, index) => (
  //           <Timeline.Item
  //             key={status.value}
  //             dot={
  //               index < numGreenBalls ? (
  //                 <CheckOutlined
  //                   className="timeline-checks-icon"
  //                   style={{
  //                     color:
  //                       index < numGreenBalls ? color : settings.colors.gray,
  //                   }}
  //                 />
  //               ) : undefined
  //             }
  //             color={index < numGreenBalls ? color : settings.colors.gray}
  //           >
  //             {status.label}
  //           </Timeline.Item>
  //         ))}
  //       </Timeline>
  //       <style jsx global>{`
  //         .status-timeline-container .ant-timeline-item-last {
  //           padding-bottom: 0px;
  //         }
  //         .status-timeline-container
  //           .ant-timeline-item-last
  //           .ant-timeline-item-content {
  //           padding-bottom: 0px;
  //           min-height: unset;
  //         }
  //       `}</style>
  //     </div>
  //   );
  // }
  return (
    <div className="status-balls-container">
      <div className="status-balls">
        <div className="gray-line" />
        <div
          className="green-line"
          style={{
            width: `${(numGreenBalls - 1) * percent * 100}%`,
            backgroundColor: color,
          }}
        />
        {statuses.map((status, index) => (
          <>
            <div
              key={status}
              className="ball-text"
              style={{
                left: `${index * percent * 100}%`,
                top:
                  responsiveMode === 'mobile'
                    ? index % 2 === 0
                      ? '20%'
                      : '80%'
                    : '33%',
              }}
            >
              <Text type="secondary">{status.label}</Text>
            </div>
            <div
              key={status.value}
              className="ball"
              style={{
                backgroundColor:
                  index < numGreenBalls ? color : settings.colors.gray,
                left: `${index * percent * 100}%`,
              }}
            />
          </>
        ))}
      </div>
      <style jsx>{`
        .status-balls-container {
          padding-left: 60px;
          padding-right: 60px;
        }
        @media screen and (max-width: 992px) {
          .status-balls-container {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        .status-balls {
          position: relative;
          height: 100px;
          width: 100%;
        }
        .ball {
          height: 16px;
          width: 16px;
          border-radius: 16px;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 66%;
        }
        @media screen and (max-width: 992px) {
          .ball {
            top: 50%;
          }
        }
        .ball-text {
          text-transform: uppercase;
          position: absolute;
          transform: translate(-50%, -50%);
          white-space: nowrap;
        }
        .gray-line {
          height: 2px;
          background-color: #e5e5e5;
          position: absolute;
          top: 66%;
          width: 100%;
          left: 0;
        }
        @media screen and (max-width: 992px) {
          .gray-line {
            top: 50%;
          }
        }
        .green-line {
          height: 2px;
          position: absolute;
          top: 66%;
          width: 0%;
          left: 0;
          transition: width 1s;
        }
        @media screen and (max-width: 992px) {
          .green-line {
            top: 50%;
          }
        }
      `}</style>
    </div>
  );
}

export default StatusBalls;
