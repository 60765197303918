import { Divider, Form, Input } from 'antd';
import React from 'react';

function AddressForm({ addressName, addressLabel, style = {} }) {
  return (
    <div style={style}>
      <Divider>{addressLabel}</Divider>
      <Form.Item
        label="Address Label"
        extra={`"My house", "My work ..."`}
        name={[addressName, 'label']}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Line 1" name={[addressName, 'line1']}>
        <Input />
      </Form.Item>
      <Form.Item label="Line 2" name={[addressName, 'line2']}>
        <Input />
      </Form.Item>
      <Form.Item label="City" name={[addressName, 'city']}>
        <Input />
      </Form.Item>
      <Form.Item label="State" name={[addressName, 'state']}>
        <Input />
      </Form.Item>
      <Form.Item label="Zip Code" name={[addressName, 'zip']}>
        <Input />
      </Form.Item>
    </div>
  );
}

export default AddressForm;
