import React, { useCallback, useMemo } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { updateCheckAction } from '../../redux-store/checks-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { updateProfileAction } from '../../redux-store/auth-store';
import useProfile from '../Profile/use-profile';

const sendCheckMutation = gql`
  mutation SendCheck($id: ID!) {
    sendCheck(id: $id) {
      id
      owner
      description
      toAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      fromAddress {
        id
        name
        company
        address1
        address2
        city
        state
        zipCode
        country
      }
      bankAccount {
        id
        label
        bankName
        accountType
        signatory
      }
      amount
      memo
      checkNumber
      expectedDeliveryDate
      mailType
      sendDate
      thumbnails {
        medium
      }
      trackingEvents {
        lobId
        name
        location
        time
      }
      currentStatus
      cancelled
      isDraft
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

const { Text } = Typography;

function SendCheckModal({ check, setCheck }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sendCheck] = useMutation(sendCheckMutation);
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useProfile();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await sendCheck({
        variables: {
          id: check.id,
        },
      });
      dispatch(updateCheckAction(result.data.sendCheck));
      dispatch(
        updateProfileAction({ checksAvailable: profile.checksAvailable - 1 }),
      );
      message.success('Check successfully scheduled for sending');
      history.push(`/check/details/${check.id}`);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [check, sendCheck, dispatch, history, profile]);

  const onNo = useCallback(() => {
    if (!loading) {
      setError(null);
      setCheck(null);
    }
  }, [setCheck, loading]);

  const reviewMessage = useMemo(() => {
    if (check) {
      let msg;
      if (check.bankAccount.label) {
        msg = `You are about to send a $${check.amount.toFixed(2)} check from ${
          check.fromAddress.name
        } to ${check.toAddress.name} using your ${
          check.bankAccount.label
        } bank account.`;
      } else {
        msg = `You are about to send a $${check.amount.toFixed(2)} check from ${
          check.fromAddress.name
        } to ${check.toAddress.name} using your bank account at ${
          check.bankAccount.bankName
        } bank account.`;
      }
      return (
        <>
          <Space direction="vertical">
            <Text>{msg}</Text>
            <Text>This will use 1 check from your checkbook.</Text>
          </Space>
        </>
      );
    }
    return '';
  }, [check]);

  return (
    <YesNoModal
      title="Send Check"
      contents={reviewMessage}
      yesText="Send Check"
      noText="Cancel"
      onYes={onYes}
      yesIcon={<SendOutlined />}
      onNo={onNo}
      visible={!!check}
      loading={loading}
      error={error}
      textAlign="left"
    />
  );
}

export default SendCheckModal;
