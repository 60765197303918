import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Select, Space, Spin, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import useScript from '../../../shared/use-script';
import useProfile from '../../Profile/use-profile';

const { Title, Text } = Typography;

export const createStripeCheckoutSessionMutation = gql`
  mutation CreateStripeCheckoutSession(
    $input: CreateStripeCheckoutSessionInput!
  ) {
    createStripeCheckoutSession(input: $input)
  }
`;

const BuyChecks = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [createStripeCheckoutSession] = useMutation(
    createStripeCheckoutSessionMutation,
  );
  const history = useHistory();

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const profile = useProfile();

  const [scriptError, scriptLoading] = useScript('https://js.stripe.com/v3/');

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      setError(null);
      try {
        const { quantity } = values;
        const result = await createStripeCheckoutSession({
          variables: {
            input: {
              lineItems: [
                {
                  code: 'SINGLE_CHECK',
                  label: 'A Single Check',
                  currency: 'usd',
                  images: [
                    'https://uploads-ssl.webflow.com/5c7b49905c2293ce4a4ac021/5f28edf579f7b53ff190227d_check.png',
                  ],
                  quantity,
                  metadata: undefined,
                },
              ],
              cancelUrl: `${window.location.origin}/checks/buy`,
              successUrl: `${window.location.origin}/checks/buy/success?session_id={CHECKOUT_SESSION_ID}`,
            },
          },
        });
        const stripe = Stripe(settings.stripeApiPublicKey);
        stripe.redirectToCheckout({
          sessionId: result.data.createStripeCheckoutSession,
        });
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    },
    [createStripeCheckoutSession],
  );

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <CSPage>
      <CSPageHeader
        title="Buy Checks"
        backActions={[
          <Button
            key="back"
            type="text"
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
          >
            Back to account
          </Button>,
        ]}
      />
      <Spin spinning={!profile} style={{ minHeight: 200 }}>
        {profile && (
          <Form
            name="buyChecks"
            onFinish={onSubmit}
            id="buyChecks"
            form={form}
            style={{ maxWidth: 500 }}
          >
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 18 }}>A single check costs $4.</Text>
            </div>
            <Form.Item
              label="How many checks would you like to buy?"
              name="quantity"
              rules={[
                {
                  required: true,
                  message:
                    "Please select the number of checks you'd like to buy",
                },
              ]}
            >
              <Select style={{ width: '100%' }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => (
                  <Select.Option key={num} value={num}>
                    {num}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ height: 16 }} />
            <Form.Item>
              <Space
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  key="cancel"
                  onClick={onBack}
                  htmlType="button"
                  type="text"
                  size="small"
                  disabled={loading}
                  icon={<ArrowLeftOutlined />}
                  style={{ marginLeft: -7 }}
                >
                  Cancel
                </Button>
                <Button
                  key="send"
                  type="primary"
                  loading={loading || scriptLoading}
                  htmlType="submit"
                  icon={<CheckOutlined />}
                >
                  Check out
                </Button>
              </Space>
            </Form.Item>
            {(error || scriptError) && (
              <div
                className="server-error ant-form-item-has-error"
                style={{ marginTop: 16, textAlign: 'center' }}
              >
                <div className="ant-form-item-explain">
                  {error || scriptError}
                </div>
              </div>
            )}
          </Form>
        )}
      </Spin>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </CSPage>
  );
};

export default BuyChecks;
