import { Empty, List, Spin, Typography } from 'antd';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { VariableSizeList } from 'react-window';
import Footer from './Footer';
import { useWindowDimsChanging } from '../shared/window-dimensions';

const { Title, Text } = Typography;

function Header({ title, style, responsiveMode, topActions, backActions }) {
  return (
    <div className="header" style={style}>
      <div className="back-actions">{backActions}</div>
      <Title level={responsiveMode === 'mobile' ? 2 : 1} style={{ margin: 0 }}>
        {title}
      </Title>
      <div className="top-actions">{topActions}</div>
      <style jsx>{`
        .back-actions {
          height: 50px;
          margin-left: -15px;
        }
        .top-actions {
          margin-left: -15px;
        }
        .header {
          padding-top: 50px;
          padding-left: 55px;
          padding-right: 55px;
          margin-bottom: 32px;
        }
        @media screen and (max-width: 992px) {
          .header {
            padding-top: 50px;
            padding-left: 55px;
            padding-right: 55px;
          }
        }
        @media screen and (max-width: 600px) {
          .header {
            padding-top: 32px;
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      `}</style>
    </div>
  );
}

function FancyListPage({
  data,
  getListItemHeight,
  topActionsHeight,
  error,
  loading,
  title,
  renderListItem,
  topActions,
  backActions,
  mobileActions,
  children,
}) {
  const listContainerRef = useRef(null);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const dimsChanging = useWindowDimsChanging();
  const [listDims, setListDims] = useState(null);
  const listDimsTimeout = useRef(null);
  useLayoutEffect(() => {
    function handleResize() {
      if (listContainerRef.current) {
        clearTimeout(listDimsTimeout.current);
        listDimsTimeout.current = setTimeout(() => {
          setListDims({
            height: listContainerRef.current.clientHeight,
            width: listContainerRef.current.clientWidth,
          });
        }, 50);
      }
    }
    if (listContainerRef.current) {
      new ResizeObserver(handleResize).observe(listContainerRef.current);
    }
  }, []);

  const {
    headerIndex,
    firstIndex,
    lastIndex,
    footerIndex,
    dataLength,
  } = useMemo(() => {
    const _dataLength = data.length || 1;
    return {
      dataLength: _dataLength,
      headerIndex: 0,
      firstIndex: 1,
      lastIndex: _dataLength,
      footerIndex: _dataLength + 1,
    };
  }, [data.length]);

  const getItemSize = useCallback(
    (index) => {
      if (index === headerIndex) {
        return 200;
      }
      if (index === footerIndex) {
        return 80;
      }
      return getListItemHeight();
    },
    [headerIndex, footerIndex, getListItemHeight],
  );

  const stickFooter = useMemo(() => {
    return (
      listDims &&
      dataLength * getItemSize(firstIndex) +
        getItemSize(headerIndex) +
        getItemSize(footerIndex) <
        listDims.height
    );
  }, [listDims, firstIndex, headerIndex, footerIndex, getItemSize, dataLength]);

  return (
    <div className="page">
      <div ref={listContainerRef} className="list-container">
        {(loading || error || dimsChanging) && (
          <>
            <Header
              title={title}
              style={{ height: getItemSize(0) }}
              responsiveMode={responsiveMode}
              topActions={topActions}
              backActions={backActions}
            />
            <div style={{ height: 32 }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin spinning={dimsChanging || loading} />
            </div>
          </>
        )}
        {error && (
          <div className="top-actions">
            <div className="ant-form-item-has-error" style={{ marginTop: 16 }}>
              <div className="ant-form-item-explain">{error}</div>
            </div>
          </div>
        )}

        {listDims && !dimsChanging && !loading && !error && (
          <List>
            <VariableSizeList
              itemCount={dataLength + 2}
              itemSize={getItemSize}
              width={listDims.width}
              height={listDims.height}
            >
              {({ index, style }) => (
                <>
                  {!loading && index === headerIndex && (
                    <Header
                      title={title}
                      style={style}
                      responsiveMode={responsiveMode}
                      topActions={topActions}
                      backActions={backActions}
                    />
                  )}
                  {index > headerIndex &&
                    index < footerIndex &&
                    !data.length && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={style}
                      />
                    )}
                  {index > headerIndex &&
                    index < footerIndex &&
                    !!data.length &&
                    renderListItem({
                      index: index - 1,
                      style,
                      isLast: index === lastIndex,
                    })}
                  {index === footerIndex && (
                    <Footer
                      style={{
                        ...style,
                        top: stickFooter ? undefined : style.top,
                        bottom: stickFooter ? 0 : undefined,
                      }}
                    />
                  )}
                </>
              )}
            </VariableSizeList>
          </List>
        )}

        {responsiveMode === 'mobile' && (
          <div className="responsive-add">{mobileActions}</div>
        )}

        {children}
      </div>
      <style jsx>{`
        .page {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          position: relative;
          height: 100vh;
        }
        @media screen and (max-width: 992px) {
          .page {
            height: calc(100vh - 72px);
          }
        }
        .list-container {
          width: 100%;
          height: 100%;
        }
        .top-actions {
          margin-left: -15px;
          padding-left: 55px;
          padding-right: 55px;
        }
        @media screen and (max-width: 992px) {
          .top-actions {
            padding-left: 55px;
            padding-right: 55px;
          }
        }
        @media screen and (max-width: 600px) {
          .top-actions {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        .footer {
          display: flex;
        }
        .responsive-add {
          position: fixed;
          bottom: 32px;
          right: 32px;
        }
      `}</style>
      <style jsx global>{`
        .list-item {
          display: flex;
          justify-content: flex-start;
          padding-left: 55px;
          padding-right: 55px;
        }
        @media screen and (max-width: 992px) {
          .list-item {
            padding-left: 55px;
            padding-right: 55px;
          }
        }
        @media screen and (max-width: 600px) {
          .list-item {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        .responsive-add-btn {
          box-shadow: 4px 4px 16px #a0a0a0;
        }
        .ant-list-split .ant-list-item {
          border: 0;
        }
      `}</style>
    </div>
  );
}

export default FancyListPage;
