import React, { useCallback, useMemo } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import {
  createAddressAction,
  updateAddressAction,
} from '../../redux-store/addresses-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import DisplayAddress from '../../components/DisplayAddress';

const createAddressMutation = gql`
  mutation createAddress($address: AddressCreateInput!) {
    createAddress(address: $address) {
      id
      owner
      label
      name
      company
      address1
      address2
      city
      state
      zipCode
      country
      verificationStatus
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

const updateAddressMutation = gql`
  mutation updateAddress($address: AddressUpdateInput!) {
    updateAddress(address: $address) {
      id
      owner
      label
      name
      company
      address1
      address2
      city
      state
      zipCode
      country
      verificationStatus
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

function VerifyAddressModal({
  verifiedResults,
  setVerifiedResults,
  addressId,
  ownerId,
  shouldUpdate,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [updateAddress] = useMutation(updateAddressMutation);
  const [createAddress] = useMutation(createAddressMutation);

  const handleSave = useCallback(async () => {
    const { values, verifiedResult } = verifiedResults;
    setLoading(true);
    setError(null);
    try {
      const addressObj = {
        label: values.label,
        name: values.name,
        company: values.company,
        address1: verifiedResult.address1,
        address2: verifiedResult.address2,
        city: verifiedResult.city,
        state: verifiedResult.state,
        zipCode: verifiedResult.zipCode,
        verificationId: verifiedResult.verifiedAddressId,
      };
      if (shouldUpdate) {
        const result = await updateAddress({
          variables: {
            address: {
              id: addressId,
              ...addressObj,
            },
          },
        });
        dispatch(updateAddressAction(result.data.updateAddress));
        message.success('Address updated successfully');
      } else {
        const result = await createAddress({
          variables: {
            address: {
              id: addressId,
              owner: ownerId,
              ...addressObj,
            },
          },
        });
        dispatch(createAddressAction(result.data.createAddress));
        message.success('Address created successfully');
      }
      history.push('/addresses');
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [
    createAddress,
    updateAddress,
    addressId,
    history,
    dispatch,
    ownerId,
    verifiedResults,
    shouldUpdate,
  ]);

  const handleCancel = useCallback(() => {
    if (!loading) {
      setError(null);
      setVerifiedResults(null);
    }
  }, [setVerifiedResults, loading]);

  const params = useMemo(() => {
    let title;
    let question;
    let contents;
    let yesText;
    let noText;
    let onYes;
    let onNo;
    if (verifiedResults) {
      switch (verifiedResults.verifiedResult.deliverability) {
        case 'deliverable':
          title = 'Verify Address';
          question =
            'We made some corrections to standardize your address and make sure it’s deliverable.  Does this look correct?';
          contents = (
            <DisplayAddress address={verifiedResults.verifiedResult} />
          );
          yesText = 'Save Address';
          noText = 'Cancel';
          onYes = handleSave;
          onNo = handleCancel;
          break;
        case 'deliverable_unnecessary_unit':
          title = 'Verify Address';
          question =
            'Your address appears deliverable, but the secondary unit information is unnecessary.  Would you like to save this address anyway?';
          contents = (
            <DisplayAddress address={verifiedResults.verifiedResult} />
          );
          yesText = 'Save Address';
          noText = 'Cancel';
          onYes = handleSave;
          onNo = handleCancel;
          break;
        case 'deliverable_incorrect_unit':
          title = 'Verify Address';
          question =
            "Your address appears deliverable to the building's default address but the secondary unit provided may not exist. There is a chance the mail will not reach the intended recipient.  Would you like to save this address anyway?";
          contents = (
            <DisplayAddress address={verifiedResults.verifiedResult} />
          );
          yesText = 'Save Address';
          noText = 'Cancel';
          onYes = handleSave;
          onNo = handleCancel;
          break;
        case 'deliverable_missing_unit':
          title = 'Verify Address';
          question =
            "Your address appears deliverable to the building's default address but is missing secondary unit information. There is a chance the mail will not reach the intended recipient.  Would you like to save this address anyway?";
          contents = (
            <DisplayAddress address={verifiedResults.verifiedResult} />
          );
          yesText = 'Save Address';
          noText = 'Cancel';
          onYes = handleSave;
          onNo = handleCancel;
          break;
        case 'undeliverable':
          title = 'Verify Address';
          question =
            'According to the USPS, this address is not deliverable.  Please try again.';
          contents = (
            <DisplayAddress address={verifiedResults.verifiedResult} />
          );
          yesText = 'OK';
          onYes = handleCancel;
          noText = null;
          break;
        default:
          break;
      }
    }
    return {
      title,
      question,
      contents,
      yesText,
      noText,
      onYes,
      onNo,
    };
  }, [handleCancel, handleSave, verifiedResults]);

  return (
    <YesNoModal
      title={params.title}
      question={params.question}
      contents={params.contents}
      yesText={params.yesText}
      noText={params.noText}
      onYes={params.onYes}
      onNo={params.onNo}
      visible={!!verifiedResults}
      loading={loading}
      error={error}
      closable={false}
      maskClosable={false}
      textAlign="left"
    />
  );
}

export default VerifyAddressModal;
