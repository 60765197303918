export const READ_CHECKS = 'READ_CHECKS';
export const CREATE_CHECK = 'CREATE_CHECK';
export const UPDATE_CHECK = 'UPDATE_CHECK';
export const DELETE_CHECK = 'DELETE_CHECK';

// eslint-disable-next-line no-shadow
export const readChecksAction = (checks) => ({
  type: READ_CHECKS,
  payload: {
    checks,
  },
});

export const createCheckAction = (check) => ({
  type: CREATE_CHECK,
  payload: {
    check,
  },
});

export const updateCheckAction = (check) => ({
  type: UPDATE_CHECK,
  payload: {
    check,
  },
});

export const deleteCheckAction = (checkId) => ({
  type: DELETE_CHECK,
  payload: {
    checkId,
  },
});

const initialChecks = [];

export function checks(state = initialChecks, action) {
  console.log('action', action);
  switch (action.type) {
    case READ_CHECKS: {
      const newState = { ...state };
      action.payload.checks.forEach((p) => {
        newState[p.id] = p;
      });
      return newState;
    }
    case CREATE_CHECK:
      return {
        ...state,
        [action.payload.check.id]: action.payload.check,
      };
    case UPDATE_CHECK:
      return {
        ...state,
        [action.payload.check.id]: action.payload.check,
      };
    case DELETE_CHECK: {
      const { checkId } = action.payload;
      return { ...state, [checkId]: undefined };
    }
    default:
      return state;
  }
}
